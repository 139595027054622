import React from 'react';

export default function AvailabilityUserOverviewTable(props) {
	return (
		<table className="table table-striped table-hover">
			<thead>
				<tr>
					<th>Naam</th>
					<th>Telefoonnummer</th>
					<th>Ingevoerd</th>
				</tr>
			</thead>
			<tbody>
				{(props.users && props.users.length && props.users.map(user =>
					(
						<tr key={user.uid}>
							<td>{user.displayName}</td>
							<td>{user.phoneNumber}</td>
							<td>{props.preferences.find(preference => preference.uid === user.uid) ?
								(
									<i className="material-icons text-success" title="Ingevoerd" data-toggle="tooltip">check_circle</i>
								) : (
									<i className="material-icons text-warning" title="Nog niet ingevoerd" data-toggle="tooltip">warning</i>
								)}
							</td>
						</tr>
					))) ||
					(
						<tr>
							<td colSpan={3}>Er zijn geen teamleden beschikbaar</td>
						</tr>
					)}
			</tbody>
		</table>
	)
};