import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { db, getNeighbourhoodId } from '../firebase/firebase';
import AvailabilityFilter from '../components/Availability/AvailabilityFilter';
import AvailabilityOverview from '../components/Availability/AvailabilityOverview';
import AvailabilityUserOverview from '../components/Availability/AvailabilityUserOverview';
import moment from 'moment';
import { firebase } from '@firebase/app';
import { showSnackbar, showSnackbarWithType } from '../components/Snackbar';
import { Prompt } from 'react-router-dom'
import withAuthorization from '../components/withAuthorization';

class Availability extends Component {

    dbref = db.neighbourhoodCollection('settings');

    state = {
        month: 'next',
        availabilityThisMonth: [],
        availabilityNextMonth: [],
        availabilityThisMonthGroupedByWeek: [],
        availabilityNextMonthGroupedByWeek: [],
        timeSlots: [{ name: 'Middag', timeOfDay: 1 }, { name: 'Vroege avond', timeOfDay: 2 }, { name: 'Late avond', timeOfDay: 3 }],
        isPlanning: false,
        isSaving: false,
        preferencesThisMonth: [],
        preferencesNextMonth: [],
        planEntriesThisMonth: [],
        planEntriesNextMonth: [],
        teams: []
    };

    thisMonth = 0;
    nextMonth = 0;
    shouldBlockNavigation = false;

    componentDidMount() {
        const today = moment();
        const next = moment().add(1, 'month');
        const thisYear = today.year();
        const nextYear = next.year();
        const thisMonth = today.month() + 1;
        const nextMonth = next.month() + 1;

        this.thisYear = thisYear;
        this.nextYear = nextYear;
        this.thisMonth = thisMonth;
        this.nextMonth = nextMonth;

        this.availabilityThisMonthListener = this.getAvailabilityDeviationQuery(thisYear, thisMonth)
            .onSnapshot(querySnapshot => this.onAvailabilityThisMonthSnapshot(thisYear, thisMonth, querySnapshot));
        this.availabilityNextMonthListener = this.getAvailabilityDeviationQuery(nextYear, nextMonth)
            .onSnapshot(querySnapshot => this.onAvailabilityNextMonthSnapshot(nextYear, nextMonth, querySnapshot));
        this.userAvailabilityPreferencesThisMonthListener = this.getUserAvailabilityPreferencesQuery(thisYear, thisMonth)
            .onSnapshot(querySnapshot => this.onUserAvailabilityPreferencesThisMonthSnapshot(querySnapshot));
        this.userAvailabilityPreferencesNextMonthListener = this.getUserAvailabilityPreferencesQuery(nextYear, nextMonth)
            .onSnapshot(querySnapshot => this.onUserAvailabilityPreferencesNextMonthSnapshot(querySnapshot));
        this.loadPlanEntriesThisMonth(thisYear, thisMonth);
        this.loadPlanEntriesNextMonth(nextYear, nextMonth);
        this.teamsUnsubscriber = this.dbref.doc('teams').collection('teams').orderBy('name').onSnapshot(this.onTeamsSnapshot);
    }

    loadPlanEntriesThisMonth = async (year, month) => {
        const querySnapshot = await this.getPlanEntriesMonthQuery(year, month).get();
        this.onPlanEntriesThisMonth(querySnapshot);
    }

    loadPlanEntriesNextMonth = async (year, month) => {
        const querySnapshot = await this.getPlanEntriesMonthQuery(year, month).get();
        this.onPlanEntriesNextMonth(querySnapshot);
    }

    componentWillUnmount() {
        if (this.availabilityThisMonthListener) this.availabilityThisMonthListener();
        if (this.availabilityNextMonthListener) this.availabilityNextMonthListener();
        if (this.userAvailabilityPreferencesThisMonthListener) this.userAvailabilityPreferencesThisMonthListener();
        if (this.userAvailabilityPreferencesNextMonthListener) this.userAvailabilityPreferencesNextMonthListener();
        if (this.teamsUnsubscriber) this.teamsUnsubscriber();
    }

    setMonth = month => {
        this.setState({ month });
    }

    toggleIsPlanning = () => {
        if (this.state.isPlanning && this.shouldBlockNavigation) {
            alert('Je hebt onopgeslagen veranderingen, weet je zeker dat je de pagina wilt verlaten?');
        } else {
            this.setState(prevState => ({
                isPlanning: !prevState.isPlanning
            }));
        }
    }

    getAvailabilityDeviationQuery = (year, month) => this.getCollectionByYearMonth('userAvailabilityDeviation', year, month);

    getUserAvailabilityPreferencesQuery = (year, month) => this.getCollectionByYearMonth('userAvailabilityPreferences', year, month);

    getPlanEntriesMonthQuery = (year, month) => this.getCollectionByYearMonth('planning', year, month);

    getCollectionByYearMonth = (collection, year, month) => {
        return db.neighbourhoodCollection(collection)
            .where('year', '==', year)
            .where('month', '==', month);
    }

    daysInMonth = (year, month) => new Date(year, month + 1, 0).getDate();

    onAvailabilityThisMonthSnapshot = (year, month, querySnapshot) => {
        let availability = this.buildAvailability(year, month, querySnapshot);
        let availabilityGroupedByWeek = this.groupBy(availability, availability => availability.weekOfYear);

        this.setState({ availabilityThisMonth: availability, availabilityThisMonthGroupedByWeek: availabilityGroupedByWeek });
    }
    onAvailabilityNextMonthSnapshot = (year, month, querySnapshot) => {
        let availability = this.buildAvailability(year, month, querySnapshot);
        let availabilityGroupedByWeek = this.groupBy(availability, availability => availability.weekOfYear);

        this.setState({ availabilityNextMonth: availability, availabilityNextMonthGroupedByWeek: availabilityGroupedByWeek });
    }

    onUserAvailabilityPreferencesThisMonthSnapshot = querySnapshot => {
        this.setState({ preferencesThisMonth: this.buildPlanning(querySnapshot) });
    }

    onUserAvailabilityPreferencesNextMonthSnapshot = querySnapshot => {
        this.setState({ preferencesNextMonth: this.buildPlanning(querySnapshot) });
    }

    onPlanEntriesThisMonth = querySnapshot => {
        const planEntriesThisMonth = this.mapQuerySnapshot(querySnapshot);
        this.setState({
            planEntriesThisMonth,
            // Map with object spread to copy without reference
            originalPlanEntriesThisMonth: planEntriesThisMonth.map(x => ({ ...x }))
        });
    }
    onPlanEntriesNextMonth = querySnapshot => {
        const planEntriesNextMonth = this.mapQuerySnapshot(querySnapshot);
        this.setState({
            planEntriesNextMonth,
            // Map with object spread to copy without reference
            originalPlanEntriesNextMonth: planEntriesNextMonth.map(x => ({ ...x }))
        });
    }

    mapQuerySnapshot = querySnapshot => {
        const array = [];

        querySnapshot.forEach(doc => {
            array.push({
                id: doc.id,
                ...doc.data()
            });
        });

        return array;
    }

    onTeamsSnapshot = async querySnapshot => {
        let teams = [];
        querySnapshot.forEach(doc => {
            const data = doc.data();
            teams.push({
                key: doc.id,
                ...data
            });
        });
        this.setState({ teams });
    }

    buildPlanning = querySnapshot => {
        return this.mapQuerySnapshot(querySnapshot)
            .map(planEntry => ({
                ...planEntry,
                weekOfYear: moment({
                    year: planEntry.year,
                    month: planEntry.month,
                    day: planEntry.dayOfMonth
                }).week()
            }));
    }

    buildAvailability = (year, month, querySnapshot) => {
        // Month - 1 because we use 1 based index and javascript uses 0 based index
        month = month - 1;
        let availabilityByDay = this.generateAvailabilityByDay(year, month);
        availabilityByDay = this.fillAvailabilityByDay(availabilityByDay, querySnapshot);
        return availabilityByDay;
    }

    generateAvailabilityByDay = (year, month) => {
        const daysInMonth = this.daysInMonth(year, month) + 1;

        const availabilityByDay = [];
        for (let i = 1; i < daysInMonth; i++) {
            let tempDate = new Date(year, month, i);
            availabilityByDay[i] = {
                date: tempDate,
                weekOfYear: moment(tempDate).week(),
                dayOfMonth: i,
                timeSlots: this.state.timeSlots.map(timeSlot => {
                    return { ...timeSlot, availability: [] };
                })
            };
        }

        return availabilityByDay;
    }

    groupBy(list, keyGetter) {
        const map = new Map();
        list.forEach((item) => {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        return map;
    }

    fillAvailabilityByDay = (availabilityByDay, querySnapshot) => {
        querySnapshot.forEach(doc => {
            const data = doc.data();
            if (availabilityByDay[data.dayOfMonth] !== undefined) {
                availabilityByDay[data.dayOfMonth].timeSlots[data.timeOfDay - 1].availability.push({
                    key: doc.id,
                    ...data
                });
            }
        });
        return availabilityByDay;
    }

    onUserPlanClick = (availability, shouldAdd) => {
        const month = availability.month === this.thisMonth ? "planEntriesThisMonth" : "planEntriesNextMonth";
        const currentPlanning = this.state[month];
        const firstTeam = this.state.teams[0]
        let newPlanning = [];

        if (shouldAdd) {
            newPlanning = currentPlanning.concat([{
                ...availability,
                group: firstTeam? firstTeam.name: ""
            }]);
        } else {
            newPlanning = currentPlanning.filter(planningEntry => planningEntry.key !== availability.key);
        }

        this.setState({ [month]: newPlanning })
        this.shouldBlockNavigation = true;
    }

    onUserGroupChange = (availability, groupName) => {
        const month = availability.month === this.thisMonth ? "planEntriesThisMonth" : "planEntriesNextMonth";
        const currentPlanning = this.state[month];

        currentPlanning.find(entry =>
            entry.dayOfMonth === availability.dayOfMonth
            && entry.timeOfDay === availability.timeOfDay
            && entry.uid === availability.uid
        ).group = groupName;

        this.setState({ [month]: currentPlanning });
        this.shouldBlockNavigation = true;
    }

    savePlanningThisMonth = async () => {
        await this.savePlanning(this.state.planEntriesThisMonth, this.state.originalPlanEntriesThisMonth);

        this.loadPlanEntriesThisMonth(this.thisYear, this.thisMonth);
    }

    savePlanningNextMonth = async () => {
        await this.savePlanning(this.state.planEntriesNextMonth, this.state.originalPlanEntriesNextMonth);

        this.loadPlanEntriesNextMonth(this.nextYear, this.nextMonth);
    }

    savePlanning = async (newPlanning, originalPlanning) => {
        this.setState({ isSaving: true });
        showSnackbar('Planning opslaan');

        const month = this.state.month === 'next' ? this.nextMonth : this.thisMonth;

        const neighbourhoodId = getNeighbourhoodId();
        const updatePlanning = firebase.functions().httpsCallable('updatePlanning');
        const response = await updatePlanning({
            newPlanning,
            neighbourhoodId,
            month,
        });

        showSnackbarWithType('Planning is opgeslagen', 'success', 5000);

        this.setState({ isPlanning: false, isSaving: false });

        return response;
    }



    componentDidUpdate = () => {
        if (this.shouldBlockNavigation) {
            window.onbeforeunload = () => true
        } else {
            window.onbeforeunload = undefined
        }
    }

    render() {
        return (
            <div className="container content">

                <AvailabilityFilter
                    setMonth={this.setMonth}
                    isPlanning={this.state.isPlanning}
                    toggleIsPlanning={this.toggleIsPlanning}
                />

                <div className={this.state.month === 'this' ? "" : "hidden"}>
                    <AvailabilityUserOverview
                        preferences={this.state.preferencesThisMonth}
                    />
                    <AvailabilityOverview
                        // availability={this.state.availabilityThisMonth}
                        availabilityGroupedByWeek={this.state.availabilityThisMonthGroupedByWeek}
                        isPlanning={this.state.isPlanning}
                        planEntries={this.state.planEntriesThisMonth}
                        preferences={this.state.preferencesThisMonth}
                        onUserPlanClick={this.onUserPlanClick}
                        onUserGroupChange={this.onUserGroupChange}
                        savePlanning={this.savePlanningThisMonth}
                        isSaving={this.state.isSaving}
                        teams={this.state.teams}
                    />
                </div>

                <div className={this.state.month === 'next' ? "" : "hidden"}>
                    <AvailabilityUserOverview
                        preferences={this.state.preferencesNextMonth}
                    />
                    <AvailabilityOverview
                        // availability={this.state.availabilityNextMonth}
                        availabilityGroupedByWeek={this.state.availabilityNextMonthGroupedByWeek}
                        isPlanning={this.state.isPlanning}
                        planEntries={this.state.planEntriesNextMonth}
                        preferences={this.state.preferencesNextMonth}
                        onUserPlanClick={this.onUserPlanClick}
                        onUserGroupChange={this.onUserGroupChange}
                        savePlanning={this.savePlanningNextMonth}
                        isSaving={this.state.isSaving}
                        teams={this.state.teams}
                    />
                </div>
                <Prompt
                    when={this.shouldBlockNavigation}
                    message='Je hebt onopgeslagen veranderingen, weet je zeker dat je de pagina wilt verlaten?'
                />
            </div>
        )
    }
}

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(withRouter(Availability));
