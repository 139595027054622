import React from 'react';

export default function TeamsTable(props) {
    return(
        <table className="table table-striped table-hover table-sm">
            <thead>
                <tr>
                    <th>Teamnaam</th>
                    <th>Kleur</th>
                    <th>Actie</th>
                </tr>
            </thead>
            <tbody>
                {props.teams.map(props.teamSetting)}
            </tbody>
        </table>
    );
}