import React from 'react';
import Modal from 'react-modal';
export default function DeleteRouteModal(props) {
  return (
    <Modal
      className="Modal__Bootstrap modal-dialog"
      closeTimeoutMS={150}
      isOpen={props.modalDeleteRouteIsOpen}
      onRequestClose={props.closeDeleteRouteModal}
      ariaHideApp={false}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">Ronde verwijderen</h4>
          <button type="button" className="close" onClick={props.closeDeleteRouteModal}>
            <span aria-hidden="true">&times;</span>
            <span className="sr-only">Annuleren</span>
          </button>
        </div>
        <div className="modal-body">
          <span>Weet je zeker dat je deze ronde wilt verwijderen?</span>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary" onClick={props.closeDeleteRouteModal}>Annuleren</button>
          <button type="button" className="btn btn-danger btn-outline" onClick={props.handleDeleteRouteSaveClicked}>Verwijderen</button>
        </div>
      </div>
    </Modal>
  )
}
