import React, { Component } from 'react';

class Changelog extends Component {

    componentDidMount() {

    }

    render() {
        return (
            <div className="container  content">

                <div className="jumbotron jumbotron-fluid">
                    <div className="container">
                        <h2>29-08-2023</h2>
                        <p className="lead"># - Planning scherm, uitleg indicatie.</p>
                        <p className="lead"># - Nieuw likje verf.</p>
                        <p className="lead"># - Sommige woorden/zinnen dikgedrukt gemaakt.</p>
                    </div>
                </div>

            </div>
        );

    }
}


export default Changelog;
