const Roles = {
	User: 'user',
	Wijkcoordinator: 'wijkcoordinator',
	Exporter: 'exporter',
	Administrator: 'administrator',
}

function hasRole(user, role) {
	if (!user || !user.roles) return false;
	return user.roles.indexOf(role) !== -1;
}

export default {
	Roles,
	hasRole
}
