import React, { Component } from 'react';
import { db, getNeighbourhoodId } from '../firebase/firebase';

const UsersContext = React.createContext(null);
export const UsersContextConsumer = UsersContext.Consumer;

export class UsersContextProvider extends Component {

	isListening = false;
	registeredWatchers = new Map();

	constructor(props) {
		super(props);

		this.state = {
			users: [],
			actions: {
				register: this.registerWatcher,
				unregister: this.unregisterWatcher,
				uidToDisplayName: this.uidToDisplayName
			}
		}

		if (this.props.listen) {
			this.mountListener();
		}
	}

	componentWillUnmount() {
		this.unmountListener();
	}

	mountListener = () => {
		const neighbourhoodId = getNeighbourhoodId();
		this.onUsersSnapshotListener = db.collection('users').where('neighbourhoods', 'array-contains', neighbourhoodId)
			.onSnapshot(this.onUsersSnapshot);
		this.isListening = true;
	}

	unmountListener = () => {
		if (this.onUsersSnapshotListener) {
			this.onUsersSnapshotListener();
		}
		this.isListening = false;
	}

	registerWatcher = ref => {
		if (!this.isListening) {
			this.mountListener();
		}
		this.registeredWatchers.set(ref, true);
	}

	unregisterWatcher = ref => {
		this.registeredWatchers.delete(ref);
		if (this.registeredWatchers.size === 0) {
			this.unmountListener();
		}
	}

	uidToDisplayName = uid => {
		const user = this.state.users.find(user => user.key === uid);
		if (!user) {
			return '';
		}
		return user.displayName;
	}

	onUsersSnapshot = usersSnapshot => {
		const users = usersSnapshot.docs.map(doc => ({
			id: doc.id,
			key: doc.id,
			uid: doc.id,
			...doc.data()
		}));
		this.setState({ users });
	}

	render() {
		return (
			<UsersContext.Provider value={this.state}>
				{this.props.children}
			</UsersContext.Provider>
		)
	}
}

// This function takes a component...
export function withUsers(Component) {
	// ...and returns another component...
	return function ComponentWithUsers(props) {
		// ... and renders the wrapped component with the context users!
		// Notice that we pass through any additional props as well
		return (
			<UsersContextConsumer>
				{usersContextState => <Component {...props} users={usersContextState.users} usersContextActions={usersContextState.actions} />}
			</UsersContextConsumer>
		);
	}
}
