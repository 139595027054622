function isPasswordValid(password) {
    const hasMinimalLength = password.length >= 8;
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    return hasMinimalLength && hasUppercase && hasLowercase && hasNumber;
}

export default {
    isPasswordValid
}
