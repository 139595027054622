import React from 'react';

export default function AvailabilityFilter(props) {
	return (
		<div className="row justify-content-end">
			<div className="col-md-4">
				<div className="btn-group btn-group-toggle" data-toggle="buttons">
					<label className="btn btn-primary this" onClick={() => props.setMonth('this')} >
						<input type="radio" name="month" id="this" autoComplete="off" readOnly /> Deze maand
				</label>
					<label className="btn btn-primary active next" onClick={() => props.setMonth('next')} >
						<input type="radio" name="month" id="next" autoComplete="off" checked readOnly /> Volgende maand
				</label>
				</div>
			</div>

			<div className="col-md-2 offset-md-2">
				<div className="btn-group btn-group-toggle" data-toggle="buttons" onClick={props.toggleIsPlanning}>
					<label className="btn btn-secondary">
						<input type="checkbox" checked={props.isPlanning} autoComplete="off" readOnly /> Plan maken
				</label>
				</div>
			</div>
		</div>
	);
}