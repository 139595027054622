import React from 'react';
import NewUserAddAction from './NewUserAddAction';
import NewUserDeleteAction from './NewUserDeleteAction';

export default function NewUserTableRowActions(props) {
    return (
        <td>
            <NewUserAddAction
                user={props.user}
                functions={props.functions}
            />

            <NewUserDeleteAction
                user={props.user}
                functions={props.functions}
            />
        </td>
    )
}