import React, { PureComponent } from 'react';
import { withUsers } from '../../context/UsersContext';
import AvailabilityUserOverviewModal from './AvailabilityUserOverviewModal';

class AvailabilityUserOverview extends PureComponent {
	state = {
		isModalOpen: false
	}

	componentDidMount() {
		this.props.usersContextActions.register(this);
	}
	componentWillUnmount() {
		this.props.usersContextActions.unregister(this);
	}

	toggleModal = () => {
		this.setState(({ isModalOpen }) => ({ isModalOpen: !isModalOpen }));
	}

	render() {
		const userCount = this.props.users.length;
		const availabilityUserCount = this.props.preferences.length;
		return (
			<div>
				<div className="row justify-content-end">
					<div className="col-md-2">
						<button className="btn btn-primary" onClick={this.toggleModal}>{availabilityUserCount}/{userCount} Ingevoerd</button>
					</div>
				</div>

				<AvailabilityUserOverviewModal
					isModalOpen={this.state.isModalOpen}
					toggleModal={this.toggleModal}
					users={this.props.users}
					preferences={this.props.preferences}
				/>
			</div>
		)
	}
}
export default withUsers(AvailabilityUserOverview);