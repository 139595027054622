import React from 'react';

import UsersTableRowActions from './NewUserTableRowActions';

export default function NewUserTableRow(props) {
    return (
        <tr key={props.user.uid}>
            <td>{props.user.name}</td>
            <td>{props.user.phoneNumber}</td>
            <td>{props.user.email}</td>
            <UsersTableRowActions
                user={props.user}
                functions={props.functions}
            />
        </tr>
    )
}
