import React from 'react';
import AvailabilityWeek from './AvailabilityWeek';

export default function AvailabilityOverview(props) {
	return (
		<div>
			{Array.from(props.availabilityGroupedByWeek, ([weekNumber, week], i) => {
				const daysInWeek = week.length;
				const plannedDays = week.filter(availability => props.planEntries.some(planEntry => planEntry.dayOfMonth === availability.dayOfMonth)).length;
				const plannedFully = plannedDays === daysInWeek;

				return (
					<div
						className="card mb-5"
						key={`week${i}${weekNumber}`}
					>
						<div className="card-header">
							<div className="row justify-content-end">

								<div className="col-md-2">
									<h4>Week {weekNumber}</h4>
								</div>

								<div className="col-md-2 offset-md-2">
									<button
										className="btn btn-primary pull-right"
										type="button"
										data-toggle="collapse"
										data-target={`#week${i}${weekNumber}`}
										aria-expanded="false"
										aria-controls={`week${i}${weekNumber}`}
									>
									</button>
								</div>

								<div className="col-md-1">
									<div className="planned-progress-indicator">
										{plannedFully ?
											(
												<i className="material-icons text-success" title="Voldoende ingepland" data-toggle="tooltip">check_circle</i>
											) : (
												<i className="material-icons text-info" title="Onvoldoende ingepland" data-toggle="tooltip">info</i>
											)}
										{plannedDays}/{daysInWeek}
									</div>
								</div>
							</div>

						</div>
						<div id={`week${i}${weekNumber}`} className="card-body collapse in">
							<AvailabilityWeek
								week={week}
								isPlanning={props.isPlanning}
								planEntries={props.planEntries}
								preferences={props.preferences}
								onUserPlanClick={props.onUserPlanClick}
								onUserGroupChange={props.onUserGroupChange}
								teams={props.teams}
							/>
						</div>
					</div>
				)
			})}

			{props.isPlanning && <button className="btn btn-primary btn-raised btn-block btn-lg" style={{'position': 'fixed', 'bottom': 0, 'left': 0}} onClick={props.savePlanning} disabled={props.isSaving}>Planning Opslaan</button>}
		</div>
	)
}
