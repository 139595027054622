/*global google*/
import React from 'react';
import Modal from 'react-modal';
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap
} from "react-google-maps";
import HeatmapLayer from 'react-google-maps/lib/components/visualization/HeatmapLayer';

export default function MapRouteModal(props) {
  return (
    <Modal
      className="Modal__Bootstrap modal-dialog"
      closeTimeoutMS={150}
      isOpen={props.modalMapRouteIsOpen}
      onRequestClose={props.closeMapRouteModal}
      ariaHideApp={false}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">Gelopen ronde bekijken</h4>
          <button type="button" className="close" onClick={props.closeMapRouteModal}>
            <span aria-hidden="true">&times;</span>
            <span className="sr-only">Annuleren</span>
          </button>
        </div>
        <div className="modal-body">
          {createMap(props)}
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary" onClick={props.closeMapRouteModal}>Sluiten</button>
        </div>
      </div>
    </Modal>
  )

  function createMap(props) {

    if (props.lastSelectedRouteHeatmapPoints.length <= 0) {
      return 'Geen heatmap data gevonden.';
    }

    const latPos = props.lastSelectedRouteHeatmapPoints[0].latitude;
    const longPos = props.lastSelectedRouteHeatmapPoints[0].longitude;

    const MyMapComponent = compose(
      withProps({
        googleMapURL:
          `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&v=3.exp&libraries=visualization`,
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div id='map' style={{ height: `250px`, width: `100%` }} />,
        mapElement: <div style={{ height: `100%` }} />,
        data: props.lastSelectedRouteHeatmapPoints
      }),
      withScriptjs,
      withGoogleMap
    )(props => (
      <GoogleMap defaultZoom={12} defaultCenter={{ lat: latPos, lng: longPos }}>
        {props.data.length && (
          <HeatmapLayer data={props.data.map(p => new google.maps.LatLng(p.latitude, p.longitude))} />
        )}
      </GoogleMap>
    ));

    return (<MyMapComponent isMarkerShown />);
  }
}
