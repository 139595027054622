import React from 'react';
import AvailabilityUserName from './AvailabilityUserName';
import AvailabilityUserPlanButtons from './AvailabilityUserPlanButtons';

export default function AvailabilityUserPlanning(props) {

    return (
        <li className="list-group-item list-group-item-action flex-column align-items-start" >
            <AvailabilityUserName
                displayName={props.displayName}
                preference={props.preference}
                amountEntriesUser={props.amountEntriesUser} />
            {props.preference && (
                <div className="d-flex w-100 justify-content-between">
                    <span>
                        {props.preference.foot && <i className="material-icons m-0 p-0 ml-2"><small className="m-0">directions_walk</small></i>}
                        {props.preference.bike && <i className="material-icons m-0 p-0 ml-2"><small className="m-0">directions_bike</small></i>}
                    </span>
                    <AvailabilityUserPlanButtons
                        user={props.user}
                        planEntry={props.planEntry}
                        preference={props.preference}
                        onUserPlanClick={props.onUserPlanClick}
                        onUserGroupChange={props.onUserGroupChange}
                        teams={props.teams}
                    />
                </div>
            )}
            {!props.planEntries.every(planEntry => {
                const preference = props.preferences.find(preference => preference.uid === planEntry.uid);
                return !props.planEntry || planEntry.group !== props.planEntry.group || !preference || !props.preference || (preference.bike && props.preference.bike) || (preference.foot && props.preference.foot)
            }) && (
                <span className="text-warning mt-2">Groep heeft verschillend vervoer</span>
            )}
        </li>
    )
}