import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import withAuthorization from '../components/withAuthorization';
import AvailabilityV2 from './Availability.v2';
import { getMonth } from '../utils/Utils';

class AvailabilityV2Current extends Component {

    state = {
        month: null,
        year: null,
        daysInMonth: null,
    };

    constructor(props) {
        super(props);

        const now = new Date();
        const month = getMonth(now.getMonth());
        const year = now.getFullYear();

        this.state.month = month;
        this.state.year = year;
        this.state.daysInMonth = new Date(year, month, 0).getDate();
    }

    render() {
        const {
            month,
            year,
            daysInMonth,
        } = this.state;

        return (
            <AvailabilityV2
                month={month}
                year={year}
                daysInMonth={daysInMonth}
            />
        )
    }
}

const authCondition = (authUser) => !!authUser;
export default withAuthorization(authCondition)(withRouter(AvailabilityV2Current));
