import React from 'react';

export default function UsersChangePhoneNumberActions(props) {
    return (
        <span>
            <button className="btn btn-primary" data-toggle="tooltip" data-placement="top" title="Telefoonnummer aanpassen"
                onClick={() => {
                    props.functions.openEditUserPhoneNumberModal(props.user);
                }}>
                <i className="material-icons">phone</i>
            </button>
        </span>
    )
}
