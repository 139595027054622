import React from 'react';

const NeighbourhoodListItem = props => {
	const { neighbourhood, toggleNeighbourhoodPrivate } = props;

	let neighbourhoodPrivateBadgeClass = 'badge-info';
	let neighbourhoodPrivateBadgeText = 'public';
	if (neighbourhood.private) {
		neighbourhoodPrivateBadgeClass = 'badge-dark';
		neighbourhoodPrivateBadgeText = 'private';
	}

	return (
		<div className="grid grid--manage-neighbourhoods">
			<div>{neighbourhood.name}</div>
			<div>{neighbourhood.userCount || 0}</div>
			<div>
				<span
					onClick={() => toggleNeighbourhoodPrivate(neighbourhood)}
					className={["badge badge-pill cursor-pointer", neighbourhoodPrivateBadgeClass].join(' ')}
				>
					{neighbourhoodPrivateBadgeText}
				</span>
			</div>
			<div className="badge badge-light">{neighbourhood.plan || 'unkown'}</div>
		</div>
	);
}

export default NeighbourhoodListItem;