import React from 'react';
export default function AvailabilityUserNonPlanning(props) {
    const selectedGroup = props.planEntry && props.teams.find(team => team.name === props.planEntry.group);
    const selectedGroupColor = selectedGroup ? selectedGroup.color : '';
    return (
        <li className="list-group-item py-3">
            {props.displayName}
            {props.planEntry &&
                <span className="col-md-auto nameGroupNonPlanMode">
                    <button className={"btn m-0 btn-raised"} style={{ backgroundColor: selectedGroupColor, color: "white" }}>{props.planEntry.group}</button>
                </span>}
        </li>
    );
}