import React from 'react';
import AvailabilityUser from './AvailabilityUser';
import AvailabilityNoUser from './AvailabilityNoUser';

export default function AvailabilitySlot(props) {
	const cardStyle = { minWidth: '210px' };
	return (
		<div style={cardStyle} className="card card--availability mb-5">
			<div className="card-header">
				<h6>{props.timeSlot.name}</h6>
			</div>
			<ul className="list-group list-group-flush p-0">
				{props.timeSlot.availability.length > 0
					? props.timeSlot.availability.map((user, i) => {
						if (props.preferences.find(preference => preference.uid === user.uid)) {
							return (
								<AvailabilityUser
									key={i}
									user={user}
									isPlanning={props.isPlanning}
									planEntry={props.planEntries.find(planEntry => planEntry.uid === user.uid)}
									planEntries={props.planEntries}
									preference={props.preferences.find(preference => preference.uid === user.uid)}
									preferences={props.preferences}
									onUserPlanClick={props.onUserPlanClick}
									onUserGroupChange={props.onUserGroupChange}
									amountEntriesUser={props.fullPlanEntries.filter(planEntry => planEntry.uid === user.uid).length}
									teams={props.teams}
								/>
							)
						} else {
							return (null);
						}
					})
					: <AvailabilityNoUser />}
			</ul>
		</div>
	)
}