import React, { Component } from 'react';
import firebase from 'firebase/app';
import 'firebase/storage';
import withAuthorization from '../components/withAuthorization';
import SchedulesTable from '../components/SchedulesTable';
import { showSnackbarWithType } from '../components/Snackbar.js';

class Schedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: '',
      month: 'next'
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleFileChange(selectorFiles) {
    this.setState({
      file: selectorFiles[0]
    });
  }

  handleInputChange(e) {
    const target = e.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleSubmit(e) {
    e.preventDefault();

    const now = new Date();
    let year = now.getFullYear();
    let month = now.getMonth() + 1;

    if (this.state.month === "next") {
      month += 1;
      if (month > 12) {
        year += 1;
        month = 1;
      }
    }

    let file = this.state.file;
    let extension = '';

    try {
      extension = file.name.split('.').pop();
    }
    catch (e) {
      showSnackbarWithType('Selecteer een bestand.', 'error');
      return;
    }

    if (extension !== "pdf" && extension !== "xls" && extension !== "xlsx") {
      showSnackbarWithType('Selecteer aub een geldig bestand (PDF of Excel)', 'error');
      return;
    }

    const metadata = {
      customMetadata: {
        year,
        month,
        date: new Date(year, month - 1, 1).getTime() // month -1 because js date is zero based. getTime because metadata can only be a string
      }
    }

    let storageRef = firebase.storage().ref();
    let uploadTask = storageRef.child(`Rooster/Rooster-${year}-${month}.${extension}`).put(file, metadata);

    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
      function (snapshot) {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED:
            document.getElementById("status").innerHTML = "Het uploaden is op " + Math.round(progress) + "%";
            break;
          case firebase.storage.TaskState.RUNNING:
            document.getElementById("status").innerHTML = "Het uploaden is op " + Math.round(progress) + "%";
            if (progress === 100) {
              document.getElementById("status").innerHTML = "Het uploaden is geslaagd";
            };
            break;
          default:
            // do nothing
        }
      }, function (error) {
        switch (error.code) {
          case 'storage/unauthorized':
            document.getElementById("status").innerHTML = "U heeft geen toestemming voor het uploaden van een rooster";
            break;
          case 'storage/canceled':
            document.getElementById("status").innerHTML = "Het uploaden is geannuleerd";
            break;
          case 'storage/unknown':
            document.getElementById("status").innerHTML = "Er is een onbekende error is voorgekomen";
            break;
          default:
            // do nothing
        }
      });
  }

  render() {
    return (
      <div className="container  content">
        <div className="row">
          <div className="col-7">
            <SchedulesTable />
          </div>
          <div className="col-5">
            <form onSubmit={this.handleSubmit} className="card mb-4 p-3 box-shadow">
              <label>Selecteer maand: </label>
              <label className="padleft">
                <input name="month" type="radio" value="current" checked={this.state.month === "current"} onChange={this.handleInputChange} />
                &nbsp;Deze maand
          </label>
              <label className="padleft">
                <input name="month" type="radio" value="next" checked={this.state.month === "next"} onChange={this.handleInputChange} />
                &nbsp;Volgende maand
          </label>
              <br />
              <input className="padleft" type="file" accept=".pdf,.xls,.xlsx" onChange={(e) => this.handleFileChange(e.target.files)} />
              <br />
              <input type="submit" className="btn btn-primary btn-raised" value="Rooster Uploaden"></input>
              <div className="status" id="status"></div>

              <div className="alert alert-info mt-3" role="alert">
                Oude roosters worden automatisch verwijderd
          </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(Schedule);
