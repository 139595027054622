import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/performance';
import RoleUtils from '../utils/RoleUtils';

const NEIGHBOURHOOD_ID_KEY = '@WeWatch.Buurtpreventie:user.neighbourhoodId';
const NEIGHBOURHOOD_DATA = '@WeWatch.Buurtpreventie:user.neighbourhood';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID
};

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

const auth = firebase.auth();
const db = firebase.firestore();
const perf = firebase.performance();

auth
  .onAuthStateChanged(
    async user => {
      if (!user) {
        localStorage.removeItem(NEIGHBOURHOOD_ID_KEY);
        return;
      }

      const rootUserRef = await db.collection('users').doc(user.uid).get();
      if (!rootUserRef.exists || !RoleUtils.hasRole(rootUserRef.data(), RoleUtils.Roles.User)) {
        localStorage.removeItem(NEIGHBOURHOOD_ID_KEY);
        localStorage.removeItem(NEIGHBOURHOOD_DATA);
        auth.signOut();
      } else {
        const userData = rootUserRef.data();
        const neighbourhoodId = userData.neighbourhoods[0];
        setNeighbourhoodId(neighbourhoodId);

        const rootUserNeighbourhoodRef = await db.collection('neighbourhoods').doc(neighbourhoodId).get();
        if (rootUserNeighbourhoodRef.exists) {
          const neighbourhoodData = rootUserNeighbourhoodRef.data();
          localStorage.setItem(NEIGHBOURHOOD_DATA, JSON.stringify(neighbourhoodData));
        }

      }
    }
  );

db.neighbourhoodCollection = pathString => {
  const neighbourhoodId = getNeighbourhoodId();
  // console.log('Querying neighbourhood', neighbourhoodId, 'collection for pathString', pathString);
  return db.collection('neighbourhoods').doc(neighbourhoodId).collection(pathString);
}

const getNeighbourhoodId = () => {
  const neighbourhoodId = localStorage.getItem(NEIGHBOURHOOD_ID_KEY);

  return neighbourhoodId;
}

const setNeighbourhoodId = (neighbourhoodId) => {
  localStorage.setItem(NEIGHBOURHOOD_ID_KEY, neighbourhoodId);

  return neighbourhoodId;
}

const getUserNeighbourhoodData = () => {
  const userNeighbourhoodData = JSON.parse(localStorage.getItem(NEIGHBOURHOOD_DATA));

  if (userNeighbourhoodData) {
    return userNeighbourhoodData;
  }
  else {
    return null;
  }

}

const getUserNeighbourhoodLocation = () => {
  const data = JSON.parse(localStorage.getItem(NEIGHBOURHOOD_DATA));

  if (data) {
    // Google Maps location format
    const location = {
      lat: parseFloat(data.latitude),
      lng: parseFloat(data.longitude)
    }
    return location;
  }
  else {
    return null;
  }

}

const getUserNeighbourhoodName = () => {
  const userNeighbourhoodData = JSON.parse(localStorage.getItem(NEIGHBOURHOOD_DATA));

  if (userNeighbourhoodData) {
    return " | Buurtpreventie " + userNeighbourhoodData.name;
  }
  else {
    return "";
  }

}

export {
  firebase,
  auth,
  db,
  perf,
  NEIGHBOURHOOD_ID_KEY,
  getNeighbourhoodId,
  setNeighbourhoodId,
  NEIGHBOURHOOD_DATA,
  getUserNeighbourhoodData,
  getUserNeighbourhoodLocation,
  getUserNeighbourhoodName
};
