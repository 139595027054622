import React, { Component } from 'react';
import { showSnackbar, showSnackbarWithType, showSnackbarWithTimeout } from '../components/Snackbar.js';
import { db } from '../firebase/firebase';
import RoutesTable from '../components/WalkedRoutes/RoutesTable';

import withAuthorization from '../components/withAuthorization';
import { withUsers } from '../context/UsersContext';

class GelopenRondes extends Component {

  constructor(props) {
    super(props);
    this.state = {
      routes: [],
      users: [],
      modalMapRouteIsOpen: false,
      modalDeleteRouteIsOpen: false,
      lastSelectedRoute: '',
      lastSelectedRouteHeatmapPoints: [],
      lastSelectedRouteLogEntries: [],
      showLastSelectedRouteLogEntries: false,
    };
    this.onRoutesSnapshotUnsubscriber = null;
    this.onUsersSnapshotUnsubscriber = null;
    this.onHeatmapPointsSnapshotUnsubscriber = null;
    this.onLogEntriesSnapshotUnsubscriber = null;
  }

  componentDidMount() {
    this.props.usersContextActions.register(this);
    showSnackbarWithTimeout('Gegevens laden..', 1000);
    this.onRoutesSnapshotUnsubscriber = db.neighbourhoodCollection('routes').orderBy('date', 'desc')
      .onSnapshot(this.onRoutesSnapshot);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.users !== state.users) {
      return {
        users: props.users
      };
    }
    // Return null to indicate no change to state.
    return null;
  }

  componentWillUnmount() {
    this.props.usersContextActions.unregister(this);
    if (this.onRoutesSnapshotUnsubscriber) this.onRoutesSnapshotUnsubscriber();
    if (this.onHeatmapPointsSnapshotUnsubscriber) this.onHeatmapPointsSnapshotUnsubscriber();
  }

  onRoutesSnapshot = querySnapshot => {
    let routes = [];
    querySnapshot.forEach(doc => {
      const data = doc.data();
      routes.push({
        ...data,
        key: doc.id,
      });
    });
    this.setState({ routes });
  }

  deleteRoute = () => {
    const route = this.state.lastSelectedRoute;
    db.neighbourhoodCollection('routes').doc(route.key).delete().then(() => {
      showSnackbar('Ronde is verwijderd');
    })
      .catch((error) => {
        console.error("Error removing document: ", error);
        showSnackbarWithType('Er is iets fout gegaan, probeer het nog een keer.', 'error');
      });
  }

  openDeleteRouteModal = (route) => {
    this.setState({ modalDeleteRouteIsOpen: true, lastSelectedRoute: route });
  }

  closeDeleteRouteModal = () => {
    this.setState({ modalDeleteRouteIsOpen: false });
  }

  handleDeleteRouteSaveClicked = () => {
    this.closeDeleteRouteModal();
    this.deleteRoute();
  }

  openMapRouteModal = (route) => {
    showSnackbarWithTimeout('Heatmap aan het laden...', 1000);

    this.onHeatmapPointsSnapshotUnsubscriber = db.neighbourhoodCollection('tracking')
      .doc(route.key)
      .onSnapshot(this.onHeatmapPointsSnapshot);

    this.setState({ lastSelectedRoute: route });
  }

  closeMapRouteModal = () => {
    this.setState({ modalMapRouteIsOpen: false });
  }

  onHeatmapPointsSnapshot = doc => {
    const docData = doc.data();

    const heatmapPoints = docData && docData.trackingPoints ? docData.trackingPoints.map((trackingPoint, i) => ({
      key: i,
      latitude: trackingPoint.loc.latitude,
      longitude: trackingPoint.loc.longitude
    })) : [];

    this.setState({ modalMapRouteIsOpen: true, lastSelectedRouteHeatmapPoints: heatmapPoints });
  }

  render() {
    return (
      <div className="container-fluid content">

        <RoutesTable
          routes={this.state.routes}
          getDisplayNameByUid={this.getDisplayNameByUid}
          openDeleteRouteModal={this.openDeleteRouteModal}
          modalDeleteRouteIsOpen={this.state.modalDeleteRouteIsOpen}
          closeDeleteRouteModal={this.closeDeleteRouteModal}
          handleDeleteRouteSaveClicked={this.handleDeleteRouteSaveClicked}
          openMapRouteModal={this.openMapRouteModal}
          modalMapRouteIsOpen={this.state.modalMapRouteIsOpen}
          closeMapRouteModal={this.closeMapRouteModal}
          lastSelectedRoute={this.state.lastSelectedRoute}
          lastSelectedRouteHeatmapPoints={this.state.lastSelectedRouteHeatmapPoints}
          history={this.props.history} // navigation router
        />

      </div>
    );
  }
  getDisplayNameByUid = (uid) => {
    const user = this.props.users.find(user => user.key === uid);
    const displayName = user ? user.displayName : 'Anoniem';
    return displayName;
  }
}

const authCondition = (authUser) => !!authUser;

export default withUsers(withAuthorization(authCondition)(GelopenRondes));
