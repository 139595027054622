import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { firebase } from '../firebase';

const withAuthorization = (authCondition) => (Component) => {
  class WithAuthorization extends React.Component {

    state = {
      rootUserData: null
    }

    componentDidMount() {
      firebase.auth.onAuthStateChanged(async authUser => {
        if (!authCondition(authUser)) {
          this.props.history.push("/login");
          this.setState({ rootUserData: null });
          return;
        }

        const rootUserDoc = await firebase.db.collection('users').doc(authUser.uid).get();
        const rootUserData = {
          id: rootUserDoc.id,
          ...rootUserDoc.data()
        }
        this.setState({ rootUserData });
      });
    }

    render() {
      return this.context.authUser ? <Component {...this.props} rootUserData={this.state.rootUserData} /> : null;
    }
  }

  WithAuthorization.contextTypes = {
    authUser: PropTypes.object,
  };

  return withRouter(WithAuthorization);
}

export default withAuthorization;
