import React from 'react';
import {
	Marker,
	InfoWindow
} from 'react-google-maps';

const NoteMarker = props => (
	<div>
		<Marker
			position={{
				lat: props.note.location.latitude,
				lng: props.note.location.longitude
			}}
			onClick={props.onClick}
		>
			{props.openNoteKey === props.note.key && (
				<InfoWindow
					onCloseClick={props.onCloseClickInfoWindow}
				>
					<div>
						<h5>{props.note.description}</h5>
						{props.note.isArchived ? (
							<button className="btn btn-success" title="Herstellen - opnieuw zichtbaar in de app" data-toggle="tooltip"
							 onClick={props.unarchiveLog}><i className="material-icons">unarchive</i>
								</button>
						) : (
								 <button className="btn btn-warning" title="Archiveren - niet meer zichtbaar in de app" data-toggle="tooltip"
									onClick={props.archiveLog}><i className="material-icons">archive</i>
									 </button>
						)}
						<button
							className="btn btn-sm btn-outline-danger"
							onClick={props.onClickDeleteNote}
						>
							Verwijderen
						</button>
					</div>
				</InfoWindow>
			)}
		</Marker>
	</div>
)

export default NoteMarker;
