import React, { Component } from 'react';
import {
	withScriptjs,
	withGoogleMap,
	GoogleMap,
} from 'react-google-maps';
import HeatmapLayer from 'react-google-maps/lib/components/visualization/HeatmapLayer'
import NewNoteMarker from './NewNoteMarker';
import NoteMarker from './NoteMarker';

class NotesMap extends Component {

	state = { openNoteKey: '' };

	onClickNoteMarker = note => {
		this.setState({ openNoteKey: note.key });

	}
	onCloseClickMarker = () => {
		this.setState({ openNoteKey: '' });
	}
	render() {
		const { props, state } = this;
		return (
			<GoogleMap
				defaultZoom={15}
				defaultCenter={{
					lat: props.location.lat,
					lng: props.location.lng
				}}
				onClick={props.onClickMap}
			>
				{props.newNote && (
					<NewNoteMarker
						newNote={props.newNote}
						onCloseClick={props.onCloseClickNewNoteMarker}
						onSubmit={props.onSubmitCreateNote}
					/>
				)}

				{props.notes.map(note => (
					<NoteMarker
						key={note.key}
						note={note}
						onClick={() => this.onClickNoteMarker(note)}
						openNoteKey={state.openNoteKey}
						onClickDeleteNote={() => props.onClickDeleteNote(note)}
						onCloseClickInfoWindow={this.onCloseClickMarker}
						unarchiveLog={() => props.unarchiveLog(note)}
						archiveLog={() => props.archiveLog(note)}
					/>
				))}
			<HeatmapLayer
			data={props.heatmapPoints}
			options={{
				dissipating: true,
				maxIntensity: this.props.heatmapMaxIntensity
			}}/>
			</GoogleMap>
		);
	}
}

export default withScriptjs(withGoogleMap(NotesMap));
