import React from 'react';

export default function FillDefaultDataBase (props){
    return (
            <button
                className="btn btn-outline-danger"
                type="button"
                onClick={props.openDefaultDatabaseModal}
                >
                <span>Database standaardwaarden instellen</span>
            </button>   
    )
};

