import React from 'react';

export default function PushNotificationsTable(props) {
    return(
        <table className="table table-striped table-hover table-sm">
            <thead>
                <tr>
                    <th>Pushnotificaties herinnering beschikbaarheid</th>
                    <th>Actie</th>
                </tr>
            </thead>
            <tbody>
                {props.settings.map(props.renderSetting)}
            </tbody>
        </table>
    );
}