import React from 'react';

export default function TimeSlotsSetting(props) {
    return (
        <form onSubmit={props.openTimeSlotsModal}>
            <div className="form-group">
                <label htmlFor="timeSlotsCount">Aantal dagdelen</label>
                <input
                    className="form-control"
                    id="timeSlotsCount"
                    name="timeSlotsCount"
                    type="number"
                    min="1"
                    max="5"
                    step="1"
                    style={{ width: 40 }}
                    aria-describedby="timeSlotsCountHelp"
                    value={props.timeSlotsCount}
                    onChange={props.onChange}
                />
                <small id="timeSlotsCountHelp" className="form-text text-muted">Het aantal dagdelen bruikbaar in de beschikbaarheid en planning</small>
                <div style={{display: 'flex', marginBottom: '3px' }}>
                    <label htmlFor="timeSlot1" style={{ width: '100px', paddingTop: '8px'}}>Dagdeel 1</label>
                    <input
                        className="form-control"
                        id="timeSlot1"
                        name="timeSlot1"
                        type="text"
                        aria-describedby="timeSlot1Help"
                        value={props.timeSlot1}
                        onChange={props.onChange}
                        disabled={props.timeSlotsCount < 1}
                    />
                </div>

                <div style={{display: 'flex', marginBottom: '3px' }}>
                    <label htmlFor="timeSlot2" style={{ width: '100px', paddingTop: '8px'}}>Dagdeel 2</label>
                    <input
                        className="form-control"
                        id="timeSlot2"
                        name="timeSlot2"
                        type="text"
                        aria-describedby="timeSlot2Help"
                        value={props.timeSlot2}
                        onChange={props.onChange}
                        disabled={props.timeSlotsCount < 2}
                    />
                </div>

                <div style={{display: 'flex', marginBottom: '3px' }}>
                    <label htmlFor="timeSlot3" style={{ width: '100px', paddingTop: '8px'}}>Dagdeel 3</label>
                    <input
                        className="form-control"
                        id="timeSlot3"
                        name="timeSlot3"
                        type="text"
                        aria-describedby="timeSlot3Help"
                        value={props.timeSlot3}
                        onChange={props.onChange}
                        disabled={props.timeSlotsCount < 3}
                    />
                </div>

                <div style={{display: 'flex', marginBottom: '3px' }}>
                    <label htmlFor="timeSlot4" style={{ width: '100px', paddingTop: '8px'}}>Dagdeel 4</label>
                    <input
                        className="form-control"
                        id="timeSlot4"
                        name="timeSlot4"
                        type="text"
                        aria-describedby="timeSlot4Help"
                        value={props.timeSlot4}
                        onChange={props.onChange}
                        disabled={props.timeSlotsCount < 4}
                    />
                </div>

                <div style={{display: 'flex', marginBottom: '3px' }}>
                    <label htmlFor="timeSlot5" style={{ width: '100px', paddingTop: '8px'}}>Dagdeel 5</label>
                    <input
                        className="form-control"
                        id="timeSlot5"
                        name="timeSlot5"
                        type="text"
                        aria-describedby="timeSlot5Help"
                        value={props.timeSlot5}
                        onChange={props.onChange}
                        disabled={props.timeSlotsCount < 5}
                    />
                </div>
            </div>
            <button
                className="btn btn-outline-primary"
                title="Opslaan"
                type="submit"
                data-toggle="tooltip">
                Opslaan
            </button>
        </form>
    );
}
