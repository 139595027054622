import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { auth, getNeighbourhoodId } from '../firebase/firebase';

import Header from './Header';
import withAuthentication from './withAuthentication';
import { UsersContextProvider } from '../context/UsersContext';

class App extends Component {

  constructor(props) {
    super(props);

    const brandPrimary = localStorage.getItem('@Buurtpreventie:theme.brandPrimary') || '#0B61A4';


    this.state = {
      authUser: null,
      brandPrimary
    };


    this.getThemeFromSettings();
  }
  
  componentWillUnmount() {
    if (this.onThemeSettingsSnapshotListener) this.onThemeSettingsSnapshotListener();
  }

  getThemeFromSettings = async () => {
    let brandPrimary = '#0B61A4';
    this.setState({ brandPrimary });
    try {
      if(!getNeighbourhoodId()){
        return;
      }

      /*
      this.onThemeSettingsSnapshotListener = this.themeSettingsDoc = await db
        .neighbourhoodCollection('settings')
        .doc('theme')
        .onSnapshot(querySnapshot => {
          if (!querySnapshot.exists) return;

          const brandPrimary = querySnapshot.data().brandPrimary;

          this.setState({ brandPrimary });
          localStorage.setItem('@Buurtpreventie:theme.brandPrimary', brandPrimary);
        });*/
    } catch (error) {
      console.error('[App.js] (getThemeFromSettings) error:', error);
    }
  }

  render() {
    return (
      <Router>
        <UsersContextProvider>
          <div>
            <Header authUser={this.state.authUser} />
            <style>
              {`:root { --primary: #0B61A4; }`}
            </style>
          </div>
        </UsersContextProvider>
      </Router>
    );
  }

  componentDidMount() {
    auth.onAuthStateChanged(authUser => {
      authUser
        ? this.setState(() => ({ authUser }))
        : this.setState(() => ({ authUser: null }));
    });
  }

}

export default withAuthentication(App);
