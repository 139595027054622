import React from 'react';

const TextInput = props => {

	let formControl = "form-control";

	if (props.touched) {
		if (!props.valid) {
			formControl = 'form-control control-error';
		} else {
			formControl = 'form-control control-success';
		}
	}

	return (
		<div className="form-group">
			<input type="text" className={formControl} {...props} />
		</div>
	);
}

export default TextInput;