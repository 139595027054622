import React from 'react';
import AvailabilitySlot from './AvailabilitySlot';
import moment from 'moment';

export default function AvailabilityRow(props) {
	const capitalizeFirstLetter = s => {
		return s.charAt(0).toUpperCase() + s.slice(1);
	}

	const plannedTimeSlots = props.availability.timeSlots.filter(availability => props.planEntries.some(planEntry => planEntry.timeOfDay === availability.timeOfDay)).length;
	const plannedFully = plannedTimeSlots >= 1;

	return (
		<div>
			<h3 className="text-center">
				{capitalizeFirstLetter(moment(props.availability.date).format('dddd D MMMM YYYY'))}
				&nbsp;&nbsp;
				{plannedFully ?
					(
						<i className="material-icons text-success" title="Voldoende ingepland" data-toggle="tooltip">check_circle</i>
					) : (
						<i className="material-icons text-info" title="Onvoldoende ingepland" data-toggle="tooltip">info</i>
					)}
			</h3>
			<div className="card-deck">
				{props.availability.timeSlots.map((timeSlot, i) => {
					return (
						<AvailabilitySlot
							key={i}
							timeSlot={timeSlot}
							isPlanning={props.isPlanning}
							planEntries={props.planEntries.filter(planEntry => planEntry.timeOfDay === timeSlot.timeOfDay)}
							preferences={props.preferences}
							onUserPlanClick={props.onUserPlanClick}
							onUserGroupChange={props.onUserGroupChange}
							fullPlanEntries={props.fullPlanEntries}
							teams={props.teams}
						/>)
				})}
			</div>
		</div>
	)
}