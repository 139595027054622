import React from 'react';

export default function InAppReminderDaysBefore(props) {
    return(
        <form onSubmit={props.openAmountOfDaysModal}>
            <div className="form-group">
                <label htmlFor="newDaysBefore">Aantal dagen van tevoren</label>
                <input
                    className="form-control"
                    id="newDaysBefore"
                    name="newDaysBefore"
                    type="number"
                    style={{ width: 40 }}
                    aria-describedby="newDaysBeforeHelp"
                    value={props.newDaysBefore}
                    onChange={props.onInputChange}
                />
                <small id="newDaysBefore" className="form-text text-muted">Hoeveel dagen voor het einde van de maand moet de push notificatie verstuurd worden?</small>
            </div>
            <button
                className="btn btn-outline-primary"
                title="Toevoegen"
                data-toggle="tooltip"
                type="submit"
            >
                Toevoegen
            </button>
        </form> 
    );
}