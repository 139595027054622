import { auth, db } from './firebase';

// Sign Up
export const doCreateUserWithEmailAndPassword = (email, password) =>
  auth.createUserWithEmailAndPassword(email, password);

// Sign In
export const doSignInWithEmailAndPassword = (email, password) => {
  // Firebase has a hard minimum password length of 6
  // Append a '0' to allow passwords with a length of 5
  if (password.length === 5) {
    password += '0';
  }

  return auth.signInWithEmailAndPassword(email, password);
}

// Sign out
export const doSignOut = () => {
  auth.signOut();
  window.location.href = 'https://' + window.location.hostname + '/inloggen';
}


// Password Reset
export const doPasswordReset = (email) =>
  auth.sendPasswordResetEmail(email);

// Password Change
export const doPasswordUpdate = (password) =>
  auth.currentUser.updatePassword(password);

export const getUserData = async _ => {
  if (!auth.currentUser) return null;
  const userDoc = await db.collection('users').doc(auth.currentUser.uid).get();
  return userDoc.data();
}
