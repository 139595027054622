import React from 'react';
import Modal from 'react-modal';

export default function ConfirmationModal(props) {
	return (
		<Modal
			className="Modal__Bootstrap modal-dialog"
			closeTimeoutMS={150}
			isOpen={props.isOpen}
			onRequestClose={props.close}
			ariaHideApp={false}
		>
			<div className="modal-content">
				<div className="modal-header">
					<h4 className="modal-title">{props.title}</h4>
					<button
						type="button"
						className="close"
						onClick={props.close}>
						<span aria-hidden="true">&times;</span>
						<span className="sr-only">{props.cancelButtonText || 'Annuleren'}</span>
					</button>
				</div>
				<div className="modal-body">
					<span>{props.message}</span>
				</div>
				<div className="modal-footer">
					<button
						type="button"
						className="btn btn-secondary"
						onClick={props.close}>
							{props.cancelButtonText || 'Annuleren'}
					</button>
					<button
						type="button"
						className={`btn btn-outline ${props.confirmButtonClassName || 'btn-success'}`}
						onClick={props.confirm}>
							{props.confirmButtonText || 'Opslaan'}
					</button>
				</div>
			</div>
		</Modal>
	)
}