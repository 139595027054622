import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/functions';
import withAuthorization from '../components/withAuthorization';
import Select from '../components/Form/Select';
import RoleUtils from '../utils/RoleUtils';
import moment from 'moment';
import { getUserNeighbourhoodData } from '../firebase/firebase';
import { showSnackbarWithType } from '../components/Snackbar';


class Export extends Component {
    constructor(props) {
        super(props);

        const months = this.getMonths()
            .sort((a, b) => b - a)
            .map(date => ({ value: date.format("YYYY-MM"), displayValue: date.format('MMMM YYYY').toUpperCase() }));

        this.state = {
            month: months[0].value,
            months,
            generatingExport: false,
            exportGenerated: false,
        }
    }

    getMonths = _ => {
        const startDate = moment('2022-01-01');
        const endDate = moment().subtract(1, 'month');

        const result = [];

        if (endDate.isBefore(startDate)) {
            throw new Error("End date must be greated than start date.");
        }

        while (startDate.isBefore(endDate)) {
            result.push(startDate.clone());
            startDate.add(1, 'month');
        }

        return result;
    }

    onInputChange = event => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({ [name]: value });
    }

    generateExport = async event => {
        event.preventDefault();

        this.setState({
            generatingExport: true,
        });

        const neighbourhoodData = getUserNeighbourhoodData();

        const downloadExportForClient = firebase.functions().httpsCallable('downloadExportForClient');
        const data = {
            month: this.state.month,
            client: neighbourhoodData.client,
        };

        try {
            const response = await downloadExportForClient(data);
            const csv = response.data;

            this.setState({
                generatingExport: false,
                exportGenerated: true,
            });

            let csvContent = `data:text/csv;charset=utf-8,${csv}`;
            var encodedUri = encodeURI(csvContent);
            var link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", `we-watch-buurtpreventie-export-${this.state.month}.csv`);
            document.body.appendChild(link); // Required for FF

            link.click();
        } catch (error) {
            showSnackbarWithType('Er is iets fout gegaan bij het genereren van een export. Probeer het later opnieuw of neem contact op.', 'error')
            console.error('Error while generating export:', error);
        }
    }

    render() {
        const {
            month,
            months,
            generatingExport,
            exportGenerated,
        } = this.state;

        const { rootUserData } = this.props;

        const loading = null === rootUserData;

        const neighbourhoodData = getUserNeighbourhoodData();

        return (
            <div>
                <div className="container  content">
                    <div className="card">
                        <div className="card-header">
                            <h4>Export {neighbourhoodData.client || ''}</h4>
                        </div>
                        <div className="card-body collapse in">
                            {loading ? <p>Exports aan het laden...</p> :
                                <div>
                                    {
                                        RoleUtils.hasRole(rootUserData, RoleUtils.Roles.Exporter) ? <div>
                                            {exportGenerated ? <p>Export succesvol</p> : generatingExport ? <p>Aan het genereren...</p> : <ExportForm
                                                month={month}
                                                months={months}
                                                onInputChange={this.onInputChange}
                                                generateExport={this.generateExport}
                                            />}
                                        </div>
                                            : <p>Geen rechten</p>
                                    }
                                </div>
                            }
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

const ExportForm = ({
    month,
    months,
    onInputChange,
    generateExport
}) => (
    <form onSubmit={generateExport}>
        <Select
            name="month"
            value={month}
            options={months}
            onChange={onInputChange}
        />
        <button
            className="btn btn-outline-primary"
            title="Exporteer"
            data-toggle="tooltip"
            type="submit"
        >
            Exporteer
        </button>
    </form>
);

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(withRouter(Export));
