import React, { Component } from 'react';

class ThankYou extends Component {
    render() {
        return (
            <div className="container content">
                <div className="text-center">
                    <div className="card">
                        <div className="card-body">
                            <h2>Het nieuwe teamlid is toegevoegd aan het buurtpreventieteam en kan gebruik maken van de mobiele app</h2>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ThankYou;
