import React from 'react';
import RouteRow from './RouteRow';
import DeleteRouteModal from './DeleteRouteModal';
import MapRouteModal from './MapRouteModal';

export default function RoutesTable(props) {
	return (
		<div className="table-wrapper-scroll-y wewatch-scrollbar">
			<table id="table-teamleden" className="table table-striped table-hover" width="100%">
				<thead>
					<tr>
						<th>Datum</th>
						<th>Start</th>
						<th>Einde</th>
						<th>Gestart door</th>
						<th>Lopers</th>
						<th>Logboekregels</th>
						<th>Acties</th>
					</tr>
				</thead>
				<tbody>
					{props.routes.map((route, i) =>
						<RouteRow
							key={route.key}
							route={route}
							getDisplayNameByUid={props.getDisplayNameByUid}
							openDeleteRouteModal={props.openDeleteRouteModal}
							openMapRouteModal={props.openMapRouteModal}
							history={props.history} // navigation router
						/>
					)}
				</tbody>
			</table>

			<DeleteRouteModal
				modalDeleteRouteIsOpen={props.modalDeleteRouteIsOpen}
				closeDeleteRouteModal={props.closeDeleteRouteModal}
				handleDeleteRouteSaveClicked={props.handleDeleteRouteSaveClicked}
			/>

			<MapRouteModal
				modalMapRouteIsOpen={props.modalMapRouteIsOpen}
				closeMapRouteModal={props.closeMapRouteModal}
				lastRouteSelected={props.lastSelectedRoute}
				lastSelectedRouteHeatmapPoints={props.lastSelectedRouteHeatmapPoints}
			/>

		</div>
	);
}
