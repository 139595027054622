import React, { Component } from 'react';
import {
	Marker,
	InfoWindow
} from 'react-google-maps';

class NewNoteMarker extends Component {
	state = {
		descriptionError: '',
		description: '',
		scheduled_delete_at: '',
	}

	onSubmit = event => {
		event.preventDefault();
		this.clearDescriptionError();

		const { description, scheduled_delete_at } = this.state;
		if (description === '') {
			this.setState({ descriptionError: 'Voer een omschrijving in.' });
			return;
		}

		if (scheduled_delete_at === '') {
			this.setState({ descriptionError: 'Voer een einddatum in.' });
			return;
		}

		this.props.onSubmit(description, scheduled_delete_at);
		this.setState({ description: '' });
	}

	onChangeDescription = event => {
		this.setState({ description: event.target.value });
	}

	onChangeScheduladDeleteAt = event => {
		this.setState({ scheduled_delete_at: event.target.value });
	}

	clearDescriptionError = () => {
		this.setState({ descriptionError: '' });
	}

	render() {
		const { props, state } = this;

		return (
			<Marker
				position={props.newNote.position}
				onClick={props.onClick}
			>

				<InfoWindow onCloseClick={props.onCloseClick}>
					<form onSubmit={this.onSubmit}>
						<h3>Hotspot aanmaken</h3>
						<div className="form-group">
							<label htmlFor="description">Omschrijving</label>
							<input
								type="text"
								id="description"
								name="description"
								className={`form-control ${state.descriptionError !== '' ? ' is-invalid' : ''}`}
								placeholder="Omschrijving..."
								value={state.description}
								onChange={this.onChangeDescription}
							/>
							<label htmlFor="scheduled_delete_at">Automatisch verwijderen op</label>
							<input
								type="date"
								id="scheduled_delete_at"
								name="scheduled_delete_at"
								className="form-control"
								value={state.scheduled_delete_at}
								onChange={this.onChangeScheduladDeleteAt}
							/>
							<div className="invalid-feedback">
								{state.descriptionError}
							</div>
						</div>
						<button
							type="submit"
							className="btn btn-outline-primary"
						>
							Aanmaken
							</button>
						<button
							className="btn btn-danger"
							onClick={props.onCloseClick}
						>
							Annuleren
							</button>
					</form>
				</InfoWindow>

			</Marker>
		)
	}
}

export default NewNoteMarker;
