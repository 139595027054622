//TODO  <i className="fa fa-spinner fa-spin"></i> as loading spinner

/*
 * @param message: text (string)
 */
export function showSnackbar(message) {

  if (message !== null) {
    var s = document.getElementById("snackbars");

    var x = document.createElement('div');
    x.id = "snackbar";
    x.className = "show";
    x.innerText = message;
    s.appendChild(x);

    setTimeout(function(){ removeSnackbar(x) }, 3000);
  }

}

/*
 * @param message: text (string)
 * @param type: text (string) ex: warning, error, info, success
 */
export function showSnackbarWithType(message, type) {

  if (message !== null) {
    var s = document.getElementById("snackbars");

    var x = document.createElement('div');
    x.id = "snackbar";
    x.className += "show " + type;
    x.innerText = message;
    s.appendChild(x);

    setTimeout(function(){ removeSnackbar(x) }, 3000);
  }

}

/*
 * @param message: text (string)
 * @param timeOut: number (int) ex: 1000 == 1 seconden
 */
export function showSnackbarWithTimeout(message, timeOut) {

  if (message !== null && timeOut !== null ) {
    var s = document.getElementById("snackbars");

    var x = document.createElement('div');
    x.id = "snackbar";
    x.className = "show";
    x.innerText = message;
    s.appendChild(x);

    setTimeout(function(){ removeSnackbar(x) }, timeOut);
  }

}

/*
 * @param message: text (string)
 * @param type: text (string) ex: warning, alert, info, success
 * @param timeOut: number (int) ex: 1000 == 1 seconden
 */
export function showSnackbarWithTypeAndTimeout(message, type, timeOut) {

  if (message !== null) {
    var s = document.getElementById("snackbars");

    var x = document.createElement('div');
    x.id = "snackbar";
    x.className += "show " + type;
    x.innerText = message;
    s.appendChild(x);

    setTimeout(function(){ removeSnackbar(x) }, timeOut);
  }

}

/*
 * Get rid of all the non show snackbars.
 */
export function removeSnackbar(element) {
  var len;
  element.className = element.className.replace("show", "");

  var snackbars = document.querySelectorAll("div[id='snackbar']:not(.show)");
  len = snackbars.length;

  for (var i = 0; i < len; i++) {
    snackbars[i].parentElement.removeChild(snackbars[i]);
  }

}
