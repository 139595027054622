import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { auth } from './../firebase';
import { getUserNeighbourhoodName } from '../firebase/firebase';
import RoleUtils from '../utils/RoleUtils';

import HomePage from '../pages/HomePage';
import Login from '../pages/auth/Login';
import Logboek from '../pages/Logboek';
import Teamleden from '../pages/Teamleden';
import Availability from '../pages/Availability';
import Register from '../pages/Register';
import NewUsers from '../pages/NewUsers';
import Schedule from '../pages/Schedule';
import GelopenRondes from '../pages/GelopenRondes';
import MapNotities from '../pages/MapNotities';
import EditSettings from '../pages/Settings/EditSettings';
import ManageNeighbourhoods from '../pages/ManageNeighbourhoods';
import FeedbackUsers from '../pages/FeedbackUsers';
import AvailabilityV2Current from '../pages/Availability.v2.current';
import AvailabilityV2Next from '../pages/Availability.v2.next';
import Changelog from '../pages/Changelog';
import Export from '../pages/Export';

const routeNames = {
  '/gelopen-rondes': 'Gelopen rondes',
  '/logboek': 'Logboekregels',
  '/map-notities': 'Hotspots',
  '/planning-current': 'Planning huidige maand',
  '/planning-next': 'Planning volgende maand',
  '/teamleden': 'Huidige teamleden',
  '/newusers': 'Nieuwe gebruikers',
  '/instellingen': 'Instellingen',
  '/versies': 'Laatste wijzigingen',
  '/export': 'Export',
}

function getKeyByValue(object, key) {
  return object[key];
}

const Header = (props, { authUser }) =>

  <Router forceRefresh={true}>
    <div>
      <div className="container-fluid navbar fixed-top navbar-expand align-items-center bg-white border-bottom box-shadow">
        <a className="navbar-brand me-2 mb-1 d-flex align-items-center" href="/" style={{ fontWeight: '500'}}>
          We Watch {getUserNeighbourhoodName()}
        </a>
        <h5 className="my-0 mr-md-auto font-weight-normal webtitle">{getKeyByValue(routeNames, window.location.pathname)}</h5>

        {authUser
          ? <HeaderAuth />
          : <HeaderNonAuth />
        }

      </div>

      <Route exact path="/" component={HomePage} />
      <Route path="/login" component={Login} />
      <Route path="/logboek" component={Logboek} />
      <Route path="/register" component={Register} />
      <Route path="/teamleden" component={Teamleden} />
      <Route path="/beschikbaarheid" component={Availability} />
      <Route path="/newusers" component={NewUsers} />
      <Route path="/schedule" component={Schedule} />
      <Route path="/gelopen-rondes" component={GelopenRondes} />
      <Route path='/map-notities' component={MapNotities} />
      <Route path='/instellingen' component={EditSettings} />
      <Route path='/manage-neighbourhoods' component={ManageNeighbourhoods} />
      <Route path='/opmerkingen' component={FeedbackUsers} />
      <Route path='/planning-current' component={AvailabilityV2Current} />
      <Route path='/planning-next' component={AvailabilityV2Next} />
      <Route path='/versies' component={Changelog} />
      <Route path='/export' component={Export} />
    </div>

  </Router>

Header.contextTypes = {
  authUser: PropTypes.object,
};


function HeaderAuth() {
  const [userData, setUserData] = useState(null);
  useEffect(() => {
    async function getUserData() {
      const userData = await auth.getUserData();
      setUserData(userData);
    }
    getUserData();
  }, []);

  return (
    <nav className="my-2 my-md-0 mr-md-3">
      <ul className="navbar-nav">
        <li className="nav-item dropdown">
          <a className={"nav-link dropdown-toggle" + (window.location.pathname === ("/gelopen-rondes" || "/logboek" || "/map-notities") ? ' active' : '')} href="# " id="dropdown06" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Logboek</a>
          <div className="dropdown-menu" aria-labelledby="dropdown06">
            <Link to="/gelopen-rondes" className={"dropdown-item text-dark" + (window.location.pathname === "/gelopen-rondes" ? ' active' : '')}>Gelopen rondes</Link>
            <Link to="/logboek" className={"dropdown-item text-dark" + (window.location.pathname === "/logboek" ? ' active' : '')}>Logboekregels</Link>
            <Link to="/map-notities" className={"dropdown-item text-dark" + (window.location.pathname === "/map-notities" ? ' active' : '')}>Hotspots</Link>
          </div>
        </li>
        <li className="nav-item dropdown">
          <a className={"nav-link dropdown-toggle" + (window.location.pathname === ("/beschikbaarheid" || "/planning-current" || "planning-next" || "/schedule") ? ' active' : '')} href="# " id="dropdown06" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Planning</a>
          <div className="dropdown-menu" aria-labelledby="dropdown06">
            <Link to="/planning-current" className={"dropdown-item  text-dark" + (window.location.pathname === "/planning-current" ? ' active' : '')}>Planning huidige maand</Link>
            <Link to="/planning-next" className={"dropdown-item text-dark" + (window.location.pathname === "/planning-next" ? ' active' : '')}>Planning volgende maand</Link>
          </div>
        </li>
        <li className="nav-item dropdown">
          <a className={"nav-link dropdown-toggle" + (window.location.pathname === ("/teamleden" || "/newusers") ? ' active' : '')} href="# " id="dropdown06" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Teamleden</a>
          <div className="dropdown-menu" aria-labelledby="dropdown06">
            <Link to="/teamleden" className={"dropdown-item text-dark" + (window.location.pathname === "/teamleden" ? ' active' : '')}>Huidige teamleden</Link>
            <Link to="/register" className={"dropdown-item text-dark" + (window.location.pathname === "/register" ? ' active' : '')}>Teamlid toevoegen</Link>
          </div>
        </li>
        <li className="nav-item dropdown">
          <a className={"nav-link dropdown-toggle" + (window.location.pathname === ("/instellingen" || "/versies") ? ' active' : '')} href="# " id="dropdown06" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Instellingen</a>
          <div className="dropdown-menu" aria-labelledby="dropdown06">
            <Link to="/instellingen" className={"nav-link" + (window.location.pathname === "/instellingen" ? ' active' : '')}>Instellingen</Link>
            <Link to="/versies" className={"nav-link" + (window.location.pathname === "/versies" ? ' active' : '')}>Versies</Link>

            {RoleUtils.hasRole(userData, RoleUtils.Roles.Exporter) && <Link to="/export" className={"nav-link" + (window.location.pathname === "/export" ? ' active' : '')}>Export</Link>}
          </div>
        </li>
      </ul>

      <button
        type="button"
        className="btn btn-primary btn-raised"
        onClick={auth.doSignOut}>
        Uitloggen
      </button>
    </nav>
  );
}


const HeaderNonAuth = () =>
  <div>
    <ul className="navbar-nav">
      <li className="nav-item">
        <Link to="/login" className="btn btn-outline-primary">Wijkcoordinator</Link>
      </li>
    </ul>
  </div>

export default Header;
