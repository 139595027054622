import React from 'react';

export default function RegisterForm(props) {
    return (
        <div>
            {/* Add hidden input with autocomplete to disable browser/plugin autocomplete */}
            <input autocomplete="false" name="hidden" tabindex="-1" aria-hidden="true" type="text" style={{ display: "none" }} />

            <div className="form-group">
                <label>Naam:</label>
                <input autocomplete="off" type="text" className="form-control" id="name" name="name" placeholder="Voornaam achternaam" required
                    value={props.name}
                    onChange={props.handleInputChange}></input><br />
            </div>

            <div className="form-group">
                <label>Telefoonnummer:</label>
                <input autocomplete="off" type="tel" className="form-control" id="phoneNumber" name="phoneNumber" placeholder="Telefoonnummer" required

                    value={props.phoneNumber}
                    onChange={props.handleInputChange}></input><br />
            </div>

            <div className="form-group">
                <label>Email:</label>
                <input autocomplete="off" type="email" className="form-control" id="email" name="email" placeholder="Email" required
                    value={props.email}
                    onChange={props.handleInputChange}></input><br />
            </div>

            <div className="form-group">
                <label className="col-form-label">Wachtwoord:</label>
                <input autocomplete="new-password" type="password" className="form-control" id="password" name="password" onChange={props.handleInputChange} pattern="^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,}" />
                <div className="alert alert-info  my-md-2">Combinatie van hoofdletter, kleine letter, een cijfer en minstens 8 karakters</div>
            </div>

            <input type="submit" className="btn btn-primary btn-raised" value="Toevoegen"></input>
        </div>
    );
}
