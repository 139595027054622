export const isFunction = functionToCheck => {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}

const MONTHS = {
    JANUARY: 1,
    FEBRUARY: 2,
    MARCH: 3,
    APRIL: 4,
    MAY: 5,
    JUNE: 6,
    JULY: 7,
    AUGUST: 8,
    SEPTEMBER: 9,
    OCTOBER: 10,
    NOVEMBER: 11,
    DECEMBER: 12,
}
export const getMonth = i => Object.values(MONTHS)[i];

export const DAYS = [
    { abbreviation: 'zo', name: 'zondag' },
    { abbreviation: 'ma', name: 'maandag' },
    { abbreviation: 'di', name: 'dinsdag' },
    { abbreviation: 'wo', name: 'woensdag' },
    { abbreviation: 'do', name: 'donderdag' },
    { abbreviation: 'vr', name: 'vrijdag' },
    { abbreviation: 'za', name: 'zaterdag' },
];

export const capitalize = s => s.charAt(0).toUpperCase() + s.slice(1);

export const blockingPrompt = async options => {
    return new Promise((resolve, reject) => {
        window.bootbox.prompt(Object.assign({
            centerVertical: true,
            callback: result => result ? resolve(result) : reject(),
        }, options));
    });
}
