import React, { PureComponent } from 'react';
import TextInput from '../Form/TextInput';
import Select from '../Form/Select';
import validate from '../Form/validator';

class CreateNeighbourhoodForm extends PureComponent {
	state = {
		formIsValid: false, //we will use this to track the overall form validity

		formControls: {
			name: {
				value: '',
				valid: false,
				touched: false,
				validationRules: {
					isRequired: true
				},
				placeholder: 'Naam van de buurtpreventie'
			},
			latitude: {
				value: '',
				valid: false,
				touched: false,
				validationRules: {
					isRequired: true
				},
				placeholder: 'Latitude (breedtegraad) van de wijk (bv 51.7265044)'
			},
			longitude: {
				value: '',
				valid: false,
				touched: false,
				validationRules: {
					isRequired: true
				},
				placeholder: 'Longitude (lengtegraad) van de wijk (bv 5.2939811)'
			},
			public: {
				value: '',
				placeholder: 'Publiek of privé?',
				valid: true,
				touched: true,
				validationRules: {
					isRequired: true,
				},
				options: [
					{ value: true, displayValue: 'Public' },
					{ value: false, displayValue: 'Private' }
				]
			}
		}
	}

	changeHandler = event => {
		const name = event.target.name;
		const value = event.target.value;

		const updatedControls = {
			...this.state.formControls
		};
		const updatedFormElement = {
			...updatedControls[name]
		};
		updatedFormElement.value = value;
		updatedFormElement.touched = true;
		updatedFormElement.valid = validate(value, updatedFormElement.validationRules);

		updatedControls[name] = updatedFormElement;

		let formIsValid = true;
		for (let inputIdentifier in updatedControls) {
			formIsValid = updatedControls[inputIdentifier].valid && formIsValid;
		}

		this.setState({
			formControls: updatedControls,
			formIsValid: formIsValid
		});
	}

	formSubmitHandler = () => {
		const formData = {};
		for (let formElementId in this.state.formControls) {
			formData[formElementId] = this.state.formControls[formElementId].value;
		}

		this.props.onSubmit(formData);
	}

	render() {
		const { formControls, formIsValid } = this.state;

		return (
			<div className="">
				<TextInput name="name"
					placeholder={formControls.name.placeholder}
					value={formControls.name.value}
					onChange={this.changeHandler}
					touched={formControls.name.touched}
					valid={formControls.name.valid}
				/>

				<TextInput name="latitude"
					placeholder={formControls.latitude.placeholder}
					value={formControls.latitude.value}
					onChange={this.changeHandler}
					touched={formControls.latitude.touched}
					valid={formControls.latitude.valid}
				/>

				<TextInput name="longitude"
					placeholder={formControls.longitude.placeholder}
					value={formControls.longitude.value}
					onChange={this.changeHandler}
					touched={formControls.longitude.touched}
					valid={formControls.longitude.valid}
				/>

				<Select name="public"
					value={formControls.public.value}
					onChange={this.changeHandler}
					options={formControls.public.options}
					touched={formControls.public.touched}
					valid={formControls.public.valid}
				/>

				<button
					className="btn btn-raised btn-primary"
					onClick={this.formSubmitHandler}
					disabled={!formIsValid}
				>
					Submit
				</button>
			</div>
		);
	}
}

export default CreateNeighbourhoodForm;
