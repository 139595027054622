import { withRouter } from "react-router-dom";
import React, { Component } from 'react';
import { db, getNeighbourhoodId } from "../firebase/firebase";
import firebase from 'firebase/app';
import 'firebase/functions';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import ReCAPTCHA from 'react-google-recaptcha';
import { showSnackbar, showSnackbarWithType } from '../components/Snackbar.js';
import ThankYou from '../components/ThankYou';
import RoleUtils from '../utils/RoleUtils';
import PasswordUtils from '../utils/PasswordUtils';

import RegisterForm from '../components/Register/RegisterForm';

let captcha;

const phoneUtil = PhoneNumberUtil.getInstance();

class Register extends Component {

	constructor(props) {
		super(props);
		this.state = {
			done: false,
			name: '',
			email: '',
			phoneNumber: '',
			e164PhoneNumber: '',
			neighbourhood: null,
			neighbourhoods: [],
			acceptedPrivacy: false,
			password: ''
		};
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);

	}

	handleInputChange(event) {
		const target = event.target;
		const value = target.value;
		const name = target.name;

		this.setState({
			[name]: value
		});
	}

	handleSubmit = async event => {
		event.preventDefault();

		let number = null;
		try {
			number = phoneUtil.parseAndKeepRawInput(this.state.phoneNumber, 'NL');
			if (!phoneUtil.isValidNumber(number)) {
				showSnackbarWithType('Vul een geldig telefoonnummer in.', 'error');
				return;
			}

			const e164PhoneNumber = phoneUtil.format(number, PhoneNumberFormat.E164);

			const neighbourhoodId = getNeighbourhoodId();
			const checkUser = firebase.functions().httpsCallable('checkUser');
			const data = { email: this.state.email.trim(), phoneNumber: e164PhoneNumber, neighbourhoodId };

			const response = await checkUser(data);
			const exists = response.data;

			if (exists.userWithPhoneNumberExists) {
				showSnackbarWithType('Er bestaat al een gebruiker met dit telefoonnummer.', 'error');
				return false;
			} else if (exists.newUserWithPhoneNumberExists) {
				showSnackbarWithType('Er bestaat al een aanvraag voor een gebruiker met dit telefoonnummer.', 'error');
				return false;
			} else if (exists.userWithEmailExists) {
				showSnackbarWithType('Er bestaat al een gebruiker met dit emailadres.', 'error');
				return false;
			} else if (exists.newUserWithEmailExists) {
				showSnackbarWithType('Er bestaat al een aanvraag voor een gebruiker met dit emailadres.', 'error');
				return false;
			}

			this.setState({
				e164PhoneNumber
			});

			captcha.execute();
		} catch (e) {
			console.error('[Register.js] (handleSubmit) exception', e);
			showSnackbarWithType('Vul een geldig telefoonnummer in.', 'error');
		}

	}

	onCaptchaChange = (value) => {
		this.addUser();
	}

	render() {
		if (!this.state.done) {
			return (
				<div className="container  content">

					<div className="alert alert-info" role="alert">
						Een gebruiker mag pas worden toegevoegd nadat de betreffende gemeente een VOG-verklaring aan hem/haar heeft verstrekt en deze ter inzage aan de coördinator is voorgelegd. Na het toevoegen krijgt hij/zij toegang tot de mobiele app als gebruiker.
					</div>

					<form onSubmit={this.handleSubmit} className="card mb-4 box-shadow" autocomplete="off">

						<div className="card-header">
							<h4 className="my-0 font-weight-normal">Registreren Teamlid</h4>
						</div>

						<div className="card-body">
							<RegisterForm
								name={this.state.name}
								phoneNumber={this.state.phoneNumber}
								email={this.state.email}
								password={this.state.password}
								handleInputChange={this.handleInputChange}
							/>
							<ReCAPTCHA
								ref={(el) => { captcha = el; }}
								size="invisible"
								sitekey="6LcI1k4UAAAAALe7Fp9GnzL9hcur0omO1KLCZedA"
								onChange={this.onCaptchaChange}
							/>
						</div>
					</form>
				</div>)
		} else {
			return (
				<ThankYou />
			)
		}
	}

	addUser() {
		const { name, email, e164PhoneNumber, password } = this.state;

		console.log(this.state);

		if (!PasswordUtils.isPasswordValid(password)) {
			showSnackbarWithType('Wachtwoord voldoet niet aan de eisen.', 'error');
			return;
		}

		showSnackbar('Gebruiker proberen toevoegen, een moment geduld aub...');

		const neighbourhoodId = getNeighbourhoodId();
		const createUser = firebase.functions().httpsCallable('createUser');
		createUser({
			email: email,
			displayName: name,
			password: password,
			phoneNumber: e164PhoneNumber,
			neighbourhoodId
		})
			.then(async userRecord => {
				await db.collection('users').doc(userRecord.data.uid).set({
					displayName: userRecord.data.displayName,
					email: email,
					phoneNumber: e164PhoneNumber,
					uid: userRecord.data.uid,
					neighbourhoods: [
						neighbourhoodId
					],
					roles: [
						RoleUtils.Roles.User
					]
				}, { merge: true });
				await db.neighbourhoodCollection('users').doc(userRecord.data.uid).set({
					uid: userRecord.data.uid,
					displayName: userRecord.data.displayName,
					email: email,
					phoneNumber: e164PhoneNumber,
					roles: [
						RoleUtils.Roles.User
					]
				});

				this.setState({ done: true });
				showSnackbar('Gebruiker is als teamlid toegevoegd');
			})
			.catch((error) => {
				console.error("Error creating user: ", JSON.stringify(error));
				showSnackbarWithType('Er is iets fout gegaan, probeer het nog een keer.', 'error');
			});
	}
}

export default withRouter(Register);

export {
	Register
};
