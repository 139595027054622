import React, { Component } from 'react';
import { firebase } from '../firebase';

class HomePage extends Component {

    componentDidMount() {
      firebase.auth.onAuthStateChanged(authUser => {
        if (authUser) {
          this.props.history.push("/newusers");
        }
      });
    }

    render() {
        return (
            <div className="container  content">

                <div className="row">
                    <div className="col-12">
                        <h1 className="display-4 text-center">Buurtpreventie Management Omgeving</h1>
                        <p className="text-center">We Watch - Samen met je buren bouwen aan een veiligere buurt.</p>
                    </div>
                </div>

                <div className="row py-5">
                    <div className="col-6 mx-auto">
                        <div className="card text-center">
                            <div className="card-header">
                                <h3 className="card-title"><strong>Inloggen als</strong></h3>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        <a href="/login" className="btn btn-block btn-outline-success">Wijkcoordinator</a>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );

    }
}


export default HomePage;
