import React from 'react';

export default function NotesHeatMapFilter(props) {
	return (
			<div>
				<span>Filter op: </span>
				<div className="btn-group btn-group-toggle" data-toggle="buttons">
					<label className="btn btn-primary active this" onClick={() => props.setDays(7)} >
						<input type="radio" name="days" id="7days" autoComplete="off" checked readOnly /> 7 dagen
					</label>
					<label className="btn btn-primary next" onClick={() => props.setDays(30)} >
						<input type="radio" name="days" id="30days" autoComplete="off" readOnly /> 30 dagen
					</label>
				</div>
				|
			</div>
	);
}
