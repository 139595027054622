import React, { Component } from 'react';
import firebase from 'firebase/app';
import 'firebase/storage';
import { db } from '../firebase/firebase';
import { showSnackbar, showSnackbarWithType, showSnackbarWithTimeout } from '../components/Snackbar.js';
import Modal from 'react-modal';
import moment from 'moment';
import 'moment/locale/nl';
moment.locale('nl');


class SchedulesTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            schedules: [],
            roles: []
        };
    }

    componentDidMount() {
        showSnackbarWithTimeout('Gegevens laden..', 1000);
        this.onSchedulesSnapshotUnsubscriber = db.neighbourhoodCollection('schedules')
            .onSnapshot(querySnapshot => {
                let schedules = querySnapshot.docs.map((doc) => {
                    return {
                        docid: doc.id,
                        ...doc.data()
                    }
                });
                this.setState({ schedules });
            });
    }

    componentWillUnmount() {
        if (this.onSchedulesSnapshotUnsubscriber) this.onSchedulesSnapshotUnsubscriber();
    }

    deleteSchedule = schedule => {
        firebase.storage().ref().child(schedule.name).delete()
            .then(() => {
                showSnackbar('Rooster is verwijderd');
            })
            .catch((error) => {
                console.error('Error removing schedule: ', error);
                showSnackbarWithType('Er is iets fout gegaan', 'error');
            });
    }

    _capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    openDeleteScheduleModal = lastSelectedSchedule => {
        this.setState({ modalDeleteScheduleIsOpen: true, lastSelectedSchedule });
    }

    closeDeleteScheduleModal = () => {
        this.setState({ modalDeleteScheduleIsOpen: false, lastSelectedSchedule: null });
    }

    handleDeleteScheduleSaveClicked = () => {
        this.deleteSchedule(this.state.lastSelectedSchedule);
        this.closeDeleteScheduleModal();
    }

    render() {
        const createScheduleRow = schedule => {
            return (
                <tr key={schedule.id}>
                    <td>{schedule.docid}</td>
                    <td>{this._capitalizeFirstLetter(moment(parseInt(schedule.metadata.date, 10)).format('MMMM Y'))}</td>
                    <td>
                        <button className="btn btn-danger" data-toggle="tooltip" data-placement="top" title="Verwijderen"
                            onClick={() => {
                                this.openDeleteScheduleModal(schedule);
                            }}>
                            <i className="material-icons">delete_forever</i>
                        </button>
                    </td>
                </tr>
            )
        };
        return (
            <div className="table-wrapper-scroll-y wewatch-scrollbar">
                <table id="table-teamleden" className="table table-striped table-hover" width="100%">
                    <thead>
                        <tr>
                            <th>Rooster</th>
                            <th>Datum</th>
                            <th>Acties</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.schedules.map(createScheduleRow)}
                    </tbody>
                </table>

                <Modal
                    className="Modal__Bootstrap modal-dialog"
                    closeTimeoutMS={150}
                    isOpen={this.state.modalDeleteScheduleIsOpen}
                    onRequestClose={this.closeDeleteScheduleModal}
                    ariaHideApp={false}
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Rooster verwijderen</h4>
                            <button type="button" className="close" onClick={this.closeDeleteScheduleModal}>
                                <span aria-hidden="true">&times;</span>
                                <span className="sr-only">Annuleren</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <span>Weet je zeker dat je deze ronde wilt verwijderen?</span>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={this.closeDeleteScheduleModal}>Annuleren</button>
                            <button type="button" className="btn btn-danger btn-outline" onClick={this.handleDeleteScheduleSaveClicked}>Verwijderen</button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default SchedulesTable;
