import React, { Component } from 'react';
import { db, getNeighbourhoodId } from "../firebase/firebase";
import NewUserTable from '../components/NewUsers/NewUserTable';
import firebase from 'firebase/app';
import 'firebase/functions';
import withAuthorization from '../components/withAuthorization';
import { showSnackbar, showSnackbarWithType, showSnackbarWithTimeout } from '../components/Snackbar.js';
import RoleUtils from '../utils/RoleUtils';
import PasswordUtils from '../utils/PasswordUtils';

class NewUsers extends Component {
    constructor(props) {
        super(props);

        this.state = {
            users: [],
            modalAddUserIsOpen: false,
            modalDeleteUserIsOpen: false,
            lastSelectedUser: '',
            inputPincode: ''
        };

        this.functions = {
            openAddUserModal: this.openAddUserModal,
            closeAddUserModal: this.closeAddUserModal,
            handleAddUserSaveClicked: this.handleAddUserSaveClicked,
            openDeleteUserModal: this.openDeleteUserModal,
            closeDeleteUserModal: this.closeDeleteUserModal,
            handleDeleteUserSaveClicked: this.handleDeleteUserSaveClicked,
            updateInputPincode: this.updateInputPincode,
            componentDidMount: this.componentDidMount,
            deleteUser: this.deleteUser,
            addUser: this.addUser,
        }
    }

    openAddUserModal = (user) => {
        this.setState({ modalAddUserIsOpen: true, lastSelectedUser: user });
    }

    closeAddUserModal = () => {
        this.setState({ modalAddUserIsOpen: false });
    }

    handleAddUserSaveClicked = (e) => {
        this.addUser();
    }

    openDeleteUserModal = (user) => {
        this.setState({ modalDeleteUserIsOpen: true, lastSelectedUser: user });
    }

    closeDeleteUserModal = () => {
        this.setState({ modalDeleteUserIsOpen: false });
    }

    handleDeleteUserSaveClicked = () => {
        showSnackbar('Gebruiker proberen te verwijderen...');
        this.closeDeleteUserModal();
        this.deleteUser(this.state.lastSelectedUser)
            .then(() => {
                showSnackbar('Gebruiker is verwijderd');
            })
            .catch((error) => {
                console.error("Error removing document: ", error);
                showSnackbarWithType('Er is iets fout gegaan, probeer het nog een keer.', 'error');
            });
    }

    updateInputPincode = (evt) => {
        this.setState({
            inputPincode: evt.target.value
        });
    }

    componentDidMount() {
        showSnackbarWithTimeout('Gegevens laden..', 1300);
        db.neighbourhoodCollection('newUserRequests')
            .onSnapshot(querySnapshot => {
                let newUsers = [];
                querySnapshot.forEach((doc) => {
                    let newUser = doc.data();
                    newUser.uid = doc.id;

                    newUsers.push(newUser);
                });
                this.setState({ users: newUsers });
            });
    }

    deleteUser(user) {
        return db.neighbourhoodCollection('newUserRequests').doc(user.uid).delete();
    }

    addUser() {
        const user = this.state.lastSelectedUser;
        const password = this.state.inputPincode;

        if (!PasswordUtils.isPasswordValid(password)) {
            showSnackbarWithType('Wachtwoord voldoet niet aan de eisen.', 'error');
            return;
        }

        showSnackbar('Gebruiker proberen toevoegen, een moment geduld aub...');

        const neighbourhoodId = getNeighbourhoodId();
        const createUser = firebase.functions().httpsCallable('createUser');
        createUser({
            email: user.email,
            displayName: user.name,
            password: password,
            phoneNumber: user.phoneNumber,
            neighbourhoodId
        })
            .then(async userRecord => {
                await db.collection('users').doc(userRecord.data.uid).set({
                    displayName: userRecord.data.displayName,
                    email: user.email,
                    phoneNumber: user.phoneNumber,
                    uid: userRecord.data.uid,
                    neighbourhoods: [
                        neighbourhoodId
                    ],
                    roles: [
                        RoleUtils.Roles.User
                    ]
                }, { merge: true });
                await db.neighbourhoodCollection('users').doc(userRecord.data.uid).set({
                    uid: userRecord.data.uid,
                    displayName: userRecord.data.displayName,
                    email: user.email,
                    phoneNumber: user.phoneNumber,
                    roles: [
                        RoleUtils.Roles.User
                    ]
                });

                this.deleteUser(user);
                this.setState({ modalAddUserIsOpen: false });
                showSnackbar('Gebruiker is als teamlid toegevoegd');
            })
            .catch((error) => {
                console.error("Error creating user: ", JSON.stringify(error));
                showSnackbarWithType('Er is iets fout gegaan, probeer het nog een keer.', 'error');
            });
    }

    render() {
        return (
            <div className="container  content">

                <div className="alert alert-info" role="alert">
                    Een gebruiker mag pas worden toegevoegd nadat de betreffende gemeente een VOG-verklaring aan hem/haar heeft verstrekt en deze ter inzage aan de coördinator is voorgelegd. Bij het toevoegen van een gebruiker krijgt hij/zij toegang tot de mobiele app als gebruiker.
                </div>

                <NewUserTable
                    users={this.state.users}
                    lastSelectedUser={this.state.lastSelectedUser}
                    modalAddUserIsOpen={this.state.modalAddUserIsOpen}
                    modalDeleteUserIsOpen={this.state.modalDeleteUserIsOpen}
                    functions={this.functions}
                />

            </div>
        );
    }
}

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(NewUsers);
