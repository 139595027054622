import React from 'react';

export default function LoginForm(props) {
    return (
        <div>
            <div className="form-group">
                <label>Email</label>
                <input
                    value={props.email}
                    onChange={props.onEmailChange}
                    className="form-control"
                    type="email"
                    placeholder="email@voorbeeld.nl"
                />
            </div>

            <div className="form-group">
                <label>Wachtwoord</label>
                <input
                    value={props.password}
                    onChange={props.onPasswordChange}
                    className="form-control"
                    type="password"
                    placeholder="Pincode"
                />
            </div>

            {props.error && <div className="alert alert-danger" role="alert">{props.error}</div>}

            {props.loading && (
                <strong>Aan het laden...</strong>
            )}

            {!props.loading && (props.shouldSetNewPassword ? (
                <button type="submit" className="btn btn-primary btn-raised">
                    Stel wachtwoord in
                </button>
            ) : (
                <button type="submit" className="btn btn-primary btn-raised">
                    Login
                </button>
            ))
            }
        </div>
    );
}
