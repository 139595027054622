import React from 'react';
export default function AvailabilityUserName(props) {
    let badgeClassname = "";
    badgeClassname = props.preference
        ? props.amountEntriesUser < props.preference.availableDays
            ? "badge-secondary"
            : props.amountEntriesUser > props.preference.availableDays
                ? "badge-warning"
                : "badge-primary"
        : "";
    return (
        <p className="d-flex w-100 justify-content-between mr-0 mb-1">
            {props.displayName}
            {props.preference
                ? <span className={"badge " + badgeClassname + " badge-pill mr-0"}>{props.amountEntriesUser}/{props.preference.availableDays}</span>
                : <i className="material-icons mr-0 p-0 ml-2 text-warning" data-toggle="tooltip" data-placement="bottom" title="Teamlid heeft beschikbaarheid nog niet ingevuld"><small className="m-0">warning</small></i>}
        </p>
    )
}