import React from 'react';
import RolesText from './RolesText';
import UsersTableRowActions from './UsersTableRowActions';
import moment from 'moment';
import 'moment/locale/nl';
moment.locale('nl');


export default function UsersTableRow(props) {
    return (
        <tr key={props.user.uid}>
            <td>{props.user.displayName}</td>
            <td>{props.user.phoneNumber}</td>
            <td>{props.user.email}</td>
            <td>{props.user.lastActivityAt ? moment(props.user.lastActivityAt.toDate()).format("L") : "Onbekend"}</td>
            <RolesText
                user={props.user}
                getRoleAlias={props.getRoleAlias}
            />
            <UsersTableRowActions
                user={props.user}
                currentUser={props.currentUser}
                functions={props.functions}
            />
        </tr>
    )
}
