import React from 'react';

export default function TimeSlotsCollapse(props) {
    return (
        <div className="card-header">
            <button
                className="btn btn-primary pull-right"
                type="button"
                data-toggle="collapse"
                data-target="#settingsTimeSlotsCollapse"
                aria-expanded="false"
                aria-controls="settingsTimeSlotsCollapse"
            >
                <span className="if-collapsed">Uitklappen</span>
                <span className="if-not-collapsed">Inklappen</span>
            </button>
            <h4>Dagdelen</h4>
        </div>
    );
}
