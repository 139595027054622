import React from 'react';

export default function InAppReminderDaysBefore(props) {
    return (

        <form onSubmit={props.openInAppReminderModal}>
            <div className="form-group">
                <label htmlFor="inAppReminderDaysBefore">In-app herinnering beschikbaarheid</label>
                <input
                    className="form-control"
                    id="inAppReminderDaysBefore"
                    name="inAppReminderDaysBefore"
                    type="number"
                    style={{ width: 40 }}
                    aria-describedby="inAppReminderDaysBeforeHelp"
                    value={props.inAppReminderDaysBefore}
                    onChange={props.onChange}
                />
                <small id="inAppReminderDaysBeforeHelp" className="form-text text-muted">Hoeveel dagen voor het einde van de maand moet de in-app notifictie zichtbaar zijn?</small>
            </div>
            <button
                className="btn btn-outline-primary"
                title="Opslaan"
                type="submit"
                data-toggle="tooltip">
                Opslaan
        </button>
        </form>

    );
}