import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { firebase, db, auth, setNeighbourhoodId } from "../../firebase/firebase";
import RoleUtils from '../../utils/RoleUtils';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import Modal from 'react-modal';
import { showSnackbar } from '../../components/Snackbar.js';
import PasswordUtils from '../../utils/PasswordUtils';
import { blockingPrompt } from '../../utils/Utils';

import LoginForm from '../../components/Login/LoginForm';

import './login.css';

const phoneUtil = PhoneNumberUtil.getInstance();

const INITIAL_STATE = {
  resetEmail: '',
  email: '',
  password: '',
  newPassword: '',
  newPasswordConfirm: '',
  shouldSetNewPassword: false,
  error: '',
  user: null,
  loading: false,
  modalResetPasswordIsOpen: false,
};

class LoginScreen extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
    this.onUsersSnapshotListener = null;
  }

  componentDidMount() {
    this.onAuthStateChangedListener = auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.props.history.push("/newusers");
      }
      this.onAuthStateChangedListener();
    });

    if (this.applicationVerifier && this.recaptchaWrapperRef) {
      this.applicationVerifier.clear()
      this.recaptchaWrapperRef.innerHTML = `<div id="recaptcha-container"></div>`
    }

    this.applicationVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible"
      }
    );
  }
  componentWillUnmount() {
    if (this.onAuthStateChangedListener) this.onAuthStateChangedListener();
  }

  onEmailChange = event => {
    this.setState({
      email: event.target.value
    });
  }

  onPasswordChange = event => {
    this.setState({
      password: event.target.value
    });
  }

  onResetEmailChange = event => {
    this.setState({
      resetEmail: event.target.value
    });
  }

  onInputChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  onSubmit = async (event) => {
    event.preventDefault();

    let {
      email,
      password,
      shouldSetNewPassword
    } = this.state;

    if (shouldSetNewPassword) {
      await this.resetPassword();
      return;
    }

    email = email.trim()
    if (password.length === 5) {
      password += '0';
    }

    try {
      this.setState({
        error: '',
        loading: true,
      });

      const user = await auth.signInWithEmailAndPassword(email, password);

      if (!PasswordUtils.isPasswordValid(password)) {
        this.setState({ error: 'Wachtwoord voldoet niet aan de eisen, stel een nieuw wachtwoord in.', user, shouldSetNewPassword: true, loading: false });
        return;
      }

      await this.verifyPhoneNumber(user);
    } catch (error) {
      if (error.code === 'auth/wrong-password') {
        this.setState({
          error: 'Email of wachtwoord onjuist.',
          loading: false,
        });
        await auth.signOut();
      }
    }
  }

  verifyPhoneNumber = async (user) => {
    try {
      const phoneNumber = user.user.phoneNumber || phoneUtil.format(await blockingPrompt({
        title: "Wat is je telefoonnummer?",
        size: 'small',
      }), PhoneNumberFormat.E164);
      await auth.signOut();

      const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
      // Send SMS verification code.
      const verificationId = await phoneAuthProvider.verifyPhoneNumber(
        phoneNumber, this.applicationVerifier);

      // Ask user for the verification code.
      const credential = firebase.auth.PhoneAuthProvider.credential(verificationId, await blockingPrompt({
        title: "Vul hier de SMS code in",
        size: 'small',
      }));

      // Link if not already linked. Catch empty
      try {
        await auth.currentUser.linkWithCredential(credential);
      } catch (error) {
        //
      }

      const result = await auth.signInWithCredential(credential);

      await this.onSuccessfullAuth(result);
    } catch (error) {
      console.error(error);
      this.setState({
        error: 'Er is iets fout gegaan.',
        loading: false,
      });
      await auth.signOut();
      return;
    };
  }

  resetPassword = async () => {
    this.setState({ error: '', loading: true });

    let { email, password, newPassword, newPasswordConfirm } = this.state;

    if (!newPassword || !newPasswordConfirm) {
      this.setState({ error: 'Vul een nieuw wachtwoord in.', loading: false });
      return;
    }

    if (!PasswordUtils.isPasswordValid(newPassword)) {
      this.setState({ error: 'Wachtwoord voldoet niet aan de eisen.', loading: false });
      return;
    }

    if (newPassword !== newPasswordConfirm) {
      this.setState({ error: 'Wachtwoorden komen niet overeen, probeer het opnieuw.', loading: false });
      return;
    }

    if (password.length === 5) {
      password += '0';
    }

    try {
      const uid = this.state.user.user.uid;
      const rootUserRef = await db.collection('users').doc(uid).get();
      const rootUserData = rootUserRef.data();
      const neighbourhoodId = rootUserData.neighbourhoods[0];
      const updateUser = firebase.functions().httpsCallable('updateUser');
      const payload = {
        uid,
        password: newPassword,
        neighbourhoodId,
      }
      await updateUser(payload);


      const userCredentials = await auth.signInWithEmailAndPassword(email, newPassword).catch(console.error);

      await this.verifyPhoneNumber(userCredentials);
    } catch (error) {
      this.setState({
        error: 'Email of wachtwoord onjuist.',
        loading: false,
      });
      await auth.signOut();
    }
  }

  onSuccessfullAuth = async (user) => {
    const {
      history,
    } = this.props;

    const rootDoc = await db.collection('users').doc(user.user.uid).get();
    const rootUserData = rootDoc.data();
    const neighbourhoodId = rootUserData.neighbourhoods[0];

    try {
      const doc = await db.collection('neighbourhoods').doc(neighbourhoodId).collection('users').doc(user.user.uid).get();
      const userData = doc.data();

      if (!RoleUtils.hasRole(userData, RoleUtils.Roles.Wijkcoordinator)) {
        this.setState({
          error: 'Je bent geen wijkcoördinator.',
          loading: false,
        });
        await auth.signOut();
      } else {
        setNeighbourhoodId(neighbourhoodId);
        history.push("/newusers");
      }
    } catch (error) {
      console.error(error);
      this.setState({
        error: 'Er is iets fout gegaan.',
        loading: false,
      });
      await auth.signOut();
    };
  }

  openResetPasswordModal = () => {
    this.setState({ modalResetPasswordIsOpen: true });
  }

  sendResetPasswordLink = () => {
    let {
      resetEmail,
    } = this.state;

    auth.sendPasswordResetEmail(resetEmail).then((value) => {
      showSnackbar('Met success een wachtwoord reset link verzonden met instructies naar ' + resetEmail);
    }).catch(error => {
      if (error.code === "auth/user-not-found") {
        showSnackbar("Het opgegeven email bestaat niet. Controleer het ingevoerde email");
      } else {
        showSnackbar(error.message);
      }

    });

    this.closeModal();

  }

  closeModal = () => {
    this.setState({ modalResetPasswordIsOpen: false });
  }

  render() {
    const {
      resetEmail,
      email,
      password,
      newPassword,
      newPasswordConfirm,
      shouldSetNewPassword,
      error,
      loading,
    } = this.state;

    const isInvalid =
      password === '' ||
      email === '';

    return (
      <div className="container">
        <form className="card mb-4 box-shadow" onSubmit={this.onSubmit}>

          <div className="card-header">
            <h4 className="my-0 font-weight-normal">Login</h4>
          </div>

          <div className="card-body">
            <LoginForm
              email={email}
              password={password}
              newPassword={newPassword}
              newPasswordConfirm={newPasswordConfirm}
              error={error}
              isInvalid={isInvalid}
              onEmailChange={this.onEmailChange}
              onPasswordChange={this.onPasswordChange}
              onNewPasswordChange={this.onInputChange}
              onNewPasswordConfirmChange={this.onInputChange}
              shouldSetNewPassword={shouldSetNewPassword}
              loading={loading}
            />

            <div ref={ref => this.recaptchaWrapperRef = ref}>
              <div id="recaptcha-container"></div>
            </div>
          </div>
        </form>
        <div className="container text-center">
          <button className="btn btn-primary" title="Wachtwoord resetten" data-toggle="tooltip"
            onClick={() => {
              this.openResetPasswordModal();
            }}>
            Wachtwoord vergeten?
          </button>
        </div>

        <Modal
          className="Modal__Bootstrap modal-dialog"
          closeTimeoutMS={150}
          isOpen={this.state.modalResetPasswordIsOpen}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Wachtwoord resetten</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeModal}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <p>Vul uw email in om een wachtwoord reset link te ontvangen waarmee uw wachtwoord kunt aanpassen.</p>
                <input
                  value={resetEmail}
                  onChange={this.onResetEmailChange}
                  className="form-control"
                  type="email"
                  placeholder="Email"
                />
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.closeModal}>Annuleren</button>
              <button type="button" className="btn btn-success" data-dismiss="modal" onClick={this.sendResetPasswordLink}>Verzenden</button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default withRouter(LoginScreen);

export {
  LoginScreen
};
