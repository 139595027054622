import React from 'react';
import CalendarDeleteButton from './CalendarDeleteButton';
import CalendarAddButton from './CalendarAddButton';
export default function AvailabilityUserPlanButtons(props) {
    const selectedGroup = props.planEntry && props.teams.find(team => team.name === props.planEntry.group);
    const selectedGroupColor = selectedGroup ? selectedGroup.color : '';
    return (
        <span>
            {!props.planEntry && <CalendarAddButton onUserPlanClick={() => props.onUserPlanClick(props.user, true)} />}
            {props.planEntry && (
                <div>
                    <div className="btn-group m-0 userGroupButtons">
                        <button style={{ backgroundColor: selectedGroupColor, color: "white" }} className={"btn dropdown-toggle m-0 btn-raised"} type="button" id="teamMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {props.planEntry.group || "?"}
                        </button>
                        <div className="dropdown-menu" aria-labelledby="teamMenu">
                            {props.teams.map(team => 
                            <button key={team.key} className="dropdown-item" style={{ backgroundColor: team.color, color: "white" }} onClick={() => props.onUserGroupChange(props.user, team.name)}>{team.name}</button>)}
                        </div>
                    </div>
                    <div className="input-group-append d-inline-block">
                        <CalendarDeleteButton onUserPlanClick={() => props.onUserPlanClick(props.user, false)} />
                    </div>
                </div>
            )}
        </span>
    )
}