import React from 'react';

export default function CalendarDeleteButton(props) {
    return (
        <button type="button" className="btn bmd-btn-icon" onClick={props.onUserPlanClick}>
            <svg style={{width:24, height:24}} viewBox="0 0 24 24">
                <path fill="#a94442" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
            </svg>
        </button>
    );
}