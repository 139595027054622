import React from 'react';
import NewUserTableRow from './NewUserTableRow';
import Modal from 'react-modal';

export default function NewUserTable(props) {
    return (
        <div className="table-wrapper-scroll-y wewatch-scrollbar">
            <table id="table-teamleden" className="table table-striped table-hover" width="100%">
                <thead>
                    <tr>
                        <th>Naam</th>
                        <th>Tel</th>
                        <th>E-mail</th>
                    </tr>
                </thead>
                <tbody>
                    {/* {this.state.users.map(createUserRow)} */}
                    {props.users.map((user) =>
                        <NewUserTableRow
                            user={user}
                            functions={props.functions}
                        />
                    )}
                </tbody>
            </table>

            <Modal
                className="Modal__Bootstrap modal-dialog"
                closeTimeoutMS={150}
                isOpen={props.modalAddUserIsOpen}
                onRequestClose={props.functions.closeAddUserModal}
                ariaHideApp={false}
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Gebruiker toevoegen</h4>
                        <button type="button" className="close" onClick={props.functions.closeAddUserModal}>
                            <span aria-hidden="true">&times;</span>
                            <span className="sr-only">Annuleren</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <span>Weet je zeker dat je deze {props.lastSelectedUser.name} wilt toevoegen? Voer dan een pincode in.</span><br />
                        <label className="col-form-label">Pincode:</label>
                        <input type="password" className="form-control" id="password" onChange={props.functions.updateInputPincode} pattern="^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,}" />
                        <div className="alert alert-info  my-md-2">Combinatie van hoofdletter, kleine letter, een cijfer en minstens 8 karakters</div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={props.functions.closeAddUserModal}>Annuleren</button>
                        <button type="button" className="btn btn-primary btn-outline" onClick={props.functions.handleAddUserSaveClicked}>Toevoegen</button>
                    </div>
                </div>
            </Modal>

            <Modal
                className="Modal__Bootstrap modal-dialog"
                closeTimeoutMS={150}
                isOpen={props.modalDeleteUserIsOpen}
                onRequestClose={props.functions.closeDeleteUserModal}
                ariaHideApp={false}
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Gebruiker verwijderen</h4>
                        <button type="button" className="close" onClick={props.functions.closeDeleteUserModal}>
                            <span aria-hidden="true">&times;</span>
                            <span className="sr-only">Annuleren</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <span>Weet je zeker dat je {props.lastSelectedUser.name} wilt verwijderen?</span>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={props.functions.closeDeleteUserModal}>Annuleren</button>
                        <button type="button" className="btn btn-danger btn-outline" onClick={props.functions.handleDeleteUserSaveClicked}>Verwijderen</button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
