import React from 'react';
import { SketchPicker } from 'react-color';

export default function InAppReminderDaysBefore(props) {
    return (
        <form onSubmit={props.saveNewTeam}>
            <div className="form-group">
                <label htmlFor="teamName">Teamnaam</label>
                <small id="teamNameHelp" className="form-text text-muted">De naam van het team</small>
                <input
                    className="form-control"
                    id="newTeam"
                    name="newTeam"
                    type="text"
                    aria-describedby="teamNameHelp"
                    onChange={props.onInputChange}
                />
            </div>

            <div className="form-group">
                <label htmlFor="teamColor">Teamkleur</label>
                <small id="teamColorHelp" className="form-text text-muted">De kleur van het team</small>
                <SketchPicker color={props.teamColor}
                    id="teamColor" aria-describedby="teamColorHelp" className="colorPicker"
                    onChangeComplete={props.onChangeTeamColor} />
            </div>

            <button
                className="btn btn-outline-primary"
                title="Toevoegen"
                data-toggle="tooltip"
                type="submit"
            >
                Toevoegen
            </button>
        </form>
    );
}
