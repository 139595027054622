/* global google */
import React, { Component } from 'react';
import { firebase, db, getUserNeighbourhoodLocation } from "../firebase/firebase";
import withAuthorization from '../components/withAuthorization';
import NotesMap from '../components/NotesMap/NotesMap';
import NotesHeatMapFilter from '../components/NotesMap/NotesHeatMapFilter';
import { showSnackbar, showSnackbarWithType } from '../components/Snackbar.js';
import Modal from 'react-modal';
import moment from 'moment';
moment.locale('nl');

class MapNotities extends Component {

    constructor(props) {
        super(props);

        this.state = {
            notes: [],
            location: {
                lat: 0,
                lng: 0
            },
            newNote: null,
            modalDeleteNoteIsOpen: false,
            lastSelectedNote: '',
            heatmapPoints: [],
            days: 7,
            heatmapMaxIntensity: 10,
        };
        this.onNotesSnapshotListener = null;
    }

    componentDidMount() {
        this.onNotesSnapshotListener = db.neighbourhoodCollection('notes')
            .orderBy('timestamp', 'desc')
            .onSnapshot(this.onNotesSnapshot);

        db.neighbourhoodCollection('tracking')
            .where('timestamp', '>', moment().add(-30, 'days').toDate())
            .get().then(this.getHeatmapPoints);

        const location = getUserNeighbourhoodLocation();
        this.setState({ location });
    }

    componentWillUnmount() {
        if (this.onNotesSnapshotListener) this.onNotesSnapshotListener();
    }

    openDeleteNoteModal = (note) => {
        this.setState({ modalDeleteNoteIsOpen: true, lastSelectedNote: note });
    }

    closeDeleteNoteModal = () => {
        this.setState({ modalDeleteNoteIsOpen: false, lastSelectedNote: '' });

    }

    onNotesSnapshot = querySnapshot => {
        let notes = [];
        querySnapshot.forEach(doc => {
            const data = doc.data();
            notes.push({
                key: doc.id,
                ...data
            });
        });
        this.setState({ notes });
    }

    onClickMap = async event => {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();

        // Place a ghost marker to show where marker will be
        this.setState({
            newNote: {
                position: { lat, lng }
            }
        });
    }

    createNote = async (description, scheduled_delete_at) => {
        const { newNote } = this.state;
        this.resetNewNote();

        try {
            await db.neighbourhoodCollection('notes').add({
                description,
                location: new firebase.firestore.GeoPoint(newNote.position.lat, newNote.position.lng),
                scheduled_delete_at: new Date(scheduled_delete_at),
                timestamp: new Date()
            });
        } catch (error) {
            console.error('Something went wrong:', error);
        }
    }

    resetNewNote = () => {
        // Remove the ghost marker
        this.setState({
            newNote: null
        });
    }

    archiveLog = (note) => {
        db.neighbourhoodCollection('notes').doc(note.key).update({
            isArchived: true
        })
            .then(() => {
                showSnackbar('Hotspot is gearchiveerd en niet meer zichtbaar in de app');
            })
            .catch((error) => {
                console.error("Error updating document: ", error);
                showSnackbarWithType('Er is iets fout gegaan, probeer het nog een keer.', 'error');
            });
    }

    unarchiveLog = (note) => {
        db.neighbourhoodCollection('notes').doc(note.key).update({
            isArchived: false
        })
            .then(() => {
                showSnackbar('Hotspot is hersteld en zichtbaar in de app');
            })
            .catch((error) => {
                console.error("Error updating document: ", error);
                showSnackbarWithType('Er is iets fout gegaan, probeer het nog een keer.', 'error');
            });
    }

    onClickDeleteNote = async note => {
        note = this.state.lastSelectedNote;

        showSnackbar('Hotspot proberen te verwijderen...');
        this.closeDeleteNoteModal();
        try {
            await db.neighbourhoodCollection('notes')
                .doc(note.key)
                .delete();
            showSnackbar('Hotspot is verwijderd.');
        } catch (error) {
            console.error('Something went wrong:', error);
            showSnackbarWithType('Er is iets fout gegaan, probeer het nog een keer.', 'error');
        }
    }
    filterByLastDays = (array, lastDays) => {
        if (!array) return []
        const lastDate = moment().startOf('day').subtract(lastDays, 'days')
        return array.filter(item => { return item.timestamp > lastDate });
    }

    setDays = days => {
        this.setState({ days });
    }

    getHeatmapPoints = querySnapshot => {
        const allHeatmapPoints = []

        querySnapshot.forEach(doc => {
            const docData = doc.data();
            const heatmapPoints = docData.trackingPoints.map((trackingPoint, i) => ({
                key: i,
                latitude: trackingPoint.loc.latitude,
                longitude: trackingPoint.loc.longitude,
                timestamp: moment(trackingPoint.ts)
            }));

            allHeatmapPoints.push(...heatmapPoints);
        });

        this.setState({ heatmapPoints: allHeatmapPoints });
    }

    mapHeatmapPoints = heatmapPoints => {
        return heatmapPoints.map(item => new google.maps.LatLng(item.latitude, item.longitude));
    }

    render() {
        const { heatmapPoints, days } = this.state;
        const heatmapPointsLastDays = this.mapHeatmapPoints(this.filterByLastDays(heatmapPoints, days));

        return (
            <div className="container content">
                <div className="row justify-content-center align-items-center">
                    <NotesHeatMapFilter
                        setDays={this.setDays}
                    />
    
                    <button className="btn btn-primary btn-raised mx-2" onClick={() => this.setState(prevState => ({ heatmapMaxIntensity: prevState.heatmapMaxIntensity - 1 }))}>-</button>
                    <span>Intensiteit aanpassen: {this.state.heatmapMaxIntensity}</span>
                    <button className="btn btn-primary btn-raised mx-2" onClick={() => this.setState(prevState => ({ heatmapMaxIntensity: prevState.heatmapMaxIntensity + 1 }))}>+</button>
                </div>
                <NotesMap
                    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&v=3.exp&libraries=visualization`}
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div id='map' style={{ height: `700px`, }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                    notes={this.state.notes}
                    newNote={this.state.newNote}
                    onSubmitCreateNote={this.createNote}
                    onCloseClickNewNoteMarker={this.resetNewNote}
                    onClickMap={this.onClickMap}
                    onClickDeleteNote={this.openDeleteNoteModal}
                    onCloseClickMarker={this.closeDeleteNoteModal}
                    unarchiveLog={this.unarchiveLog}
                    archiveLog={this.archiveLog}
                    heatmapPoints={heatmapPointsLastDays}
                    heatmapMaxIntensity={this.state.heatmapMaxIntensity}
                    location={this.state.location}
                />

                <Modal
                    className="Modal__Bootstrap modal-dialog"
                    closeTimeoutMS={150}
                    isOpen={this.state.modalDeleteNoteIsOpen}
                    onRequestClose={this.closeDeleteNoteModal}
                    ariaHideApp={false}

                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Hotspot verwijderen</h4>
                            <button type="button" className="close" onClick={this.closeDeleteNoteModal}>
                                <span aria-hidden="true">&times;</span>
                                <span className="sr-only">Annuleren</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <span>Weet je zeker dat je deze hotspot wilt verwijderen?</span>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={this.closeDeleteNoteModal}>Annuleren</button>
                            <button type="button" className="btn btn-danger btn-outline" onClick={this.onClickDeleteNote}>Verwijderen</button>
                        </div>
                    </div>
                </Modal>

            </div >
        )
    }
}
const authCondition = (authUser) => !!authUser;
export default withAuthorization(authCondition)(MapNotities);
