import React, { Component } from 'react';
import withAuthorization from '../components/withAuthorization';
import NeighbourhoodList from '../components/NeighbourhoodsList/NeighbourhoodList';
import { showSnackbarWithTimeout } from '../components/Snackbar.js';
import { db } from '../firebase/firebase';
import CreateNeighbourhoodForm from '../components/NeighbourhoodForm/CreateNeighbourhoodForm';

class ManageNeighbourhoods extends Component {
	state = {
		neighbourhoods: []
	}

	componentDidMount() {
		showSnackbarWithTimeout('Gegevens laden..', 1300);

		// Realtime
		// this.onUsersSnapshotUnsubscriber = db.collection('neighbourhoods').onSnapshot(this.onNeighourhoodsSnapshot);
		// Once
		this.loadNeighbourhoods();
	}

	componentWillUnmount() {
		this.onUsersSnapshotUnsubscriber && this.onUsersSnapshotUnsubscriber();
	}

	loadNeighbourhoods = async _ => {
		this.neighbourhoodRef = db.collection('neighbourhoods');
		this.neighbourhoodQuery = this.neighbourhoodRef.get();
		const neighbourhoodsQuerySnapshot = await this.neighbourhoodQuery;
		const neighbourhoods = this.unwrapQuerySnapshot(neighbourhoodsQuerySnapshot);
		this.setState({ neighbourhoods });
	}

	loadNextNeighbourhoods = async _ => {
		const lastNeighbourhood = this.state.neighbourhoods[this.state.neighbourhoods.length - 1];
		this.neighbourhoodQuery = this.neighbourhoodQuery.after(lastNeighbourhood.id);
		const neighbourhoodsQuerySnapshot = await this.neighbourhoodQuery;
		const neighbourhoods = this.unwrapQuerySnapshot(neighbourhoodsQuerySnapshot);
		this.setState({ neighbourhoods });
	}

	unwrapQuerySnapshot = querySnapshot => {
		const docs = [];
		querySnapshot.forEach(doc => {
			const data = doc.data();
			docs.push({
				id: doc.id,
				key: doc.id,
				...data
			});
		});
		return docs;
	}

	onNeighourhoodsSnapshot = querySnapshot => {
		const neighbourhoods = this.unwrapQuerySnapshot(querySnapshot);
		this.setState({ neighbourhoods });
	}

	onCreateNeighbourhoodFormSubmit = async formData => {
		await db.collection('neighbourhoods').add(formData);
	}

	toggleNeighbourhoodPrivate = async neighbourhood => {
		await db.collection('neighbourhoods').doc(neighbourhood.id)
			.update({ private: !neighbourhood.private });

		this.setState(state => {
			const neighbourhoods = state.neighbourhoods.map((item) => {
				if (item.id === neighbourhood.id) {
					item.private = !neighbourhood.private;
				}
				return item;
			});

			return {
				neighbourhoods,
			};
		});
	}

	render() {
		const { neighbourhoods } = this.state;

		return (
			<div className="container  content">

				<div>
					<CreateNeighbourhoodForm onSubmit={this.onCreateNeighbourhoodFormSubmit} />
				</div>

				<div>
					<NeighbourhoodList
						neighbourhoods={neighbourhoods}
						toggleNeighbourhoodPrivate={this.toggleNeighbourhoodPrivate}
					/>
				</div>
			</div>
		);
	}
}

const authCondition = (authUser) => !!authUser;
export default withAuthorization(authCondition)(ManageNeighbourhoods);
