import React from 'react';

export default function UsersChangePincodeActions(props) {
    return (
        <span>
            <button className="btn btn-primary" data-toggle="tooltip" data-placement="top" title="Wachtwoord aanpassen"
                onClick={() => {
                    props.functions.openEditUserModal(props.user);
                }}>
                <i className="material-icons">lock</i>
            </button>
        </span>
    )
}
