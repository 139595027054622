import React from 'react';
import AvailabilityRow from './AvailabilityRow';

export default function AvailabilityWeek(props) {
    return (
        <div>
            {props.week.map((availability, i) => {
                return (
                    <AvailabilityRow
                        key={i}
                        availability={availability}
                        isPlanning={props.isPlanning}
                        fullPlanEntries={props.planEntries}
                        planEntries={props.planEntries.filter(planEntry => planEntry.dayOfMonth === availability.dayOfMonth)}
                        preferences={props.preferences}
                        onUserPlanClick={props.onUserPlanClick}
                        onUserGroupChange={props.onUserGroupChange}
                        teams={props.teams}
                    />)
            })}
        </div>
    )
}