import React from 'react';
import NeighbourhoodListItem from './NeighbourhoodListItem';

const NeighbourhoodList = props => {
	const { neighbourhoods, toggleNeighbourhoodPrivate } = props;

	return (
		<div>
			{neighbourhoods.map(neighbourhood => <NeighbourhoodListItem key={neighbourhood.key} neighbourhood={neighbourhood} toggleNeighbourhoodPrivate={toggleNeighbourhoodPrivate} />)}
		</div>
	);
}

export default NeighbourhoodList;