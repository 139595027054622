import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { db, getNeighbourhoodId } from '../../firebase/firebase';
import RoleUtils from '../../utils/RoleUtils';
import firebase from 'firebase/app';
import 'firebase/functions';

import TimeSlotsCollapse from '../../components/Settings/TimeSlotsCollapse'
import TimeSlotsSetting from '../../components/Settings/TimeSlotsSetting'
import NotificationCollapse from '../../components/Settings/NotificationCollapse'
import InAppReminderDaysBefore from '../../components/Settings/InAppReminderDaysBefore'
import PushNotificationReminderDaysBefore from '../../components/Settings/PushNotificationReminderDaysBefore'
import PushNotificationsTable from '../../components/Settings/PushNotificationsTable'
import ToggleInstallationCard from '../../components/Settings/ToggleInstallationCard'
import ToggleTeamsCard from '../../components/Settings/ToggleTeamsCard'
import TeamsAddTeam from '../../components/Settings/TeamsAddTeam'
import TeamsTable from '../../components/Settings/TeamsTable'
import { showSnackbar, showSnackbarWithType } from '../../components/Snackbar';
import withAuthorization from '../../components/withAuthorization';
import ConfirmationModal from '../../components/ConfirmationModal';
import FillDefaultDataBase from '../../components/Settings/DefaultValuesButton';

class EditSettings extends Component {
    constructor(props) {
        super(props);
        this.settingsUnsubscriber = null;

        this.dbref = db.neighbourhoodCollection('settings');

        this.state = {
            teams: [],
            settings: [],
            inAppReminderDaysBefore: 1,
            brandPrimary: "",
            newDaysBefore: 1,
            newTeam: '',
            teamColor: '#000000',
            selectedDaysBefore: null,
            modalPrimaryColorIsOpen: false,
            modalInAppReminderIsOpen: false,
            modalAmountOfDaysIsOpen: false,
            modalAmountOfDaysDeleteIsOpen: false,
            modalDefaultDatabaseIsOpen: false,
            modalTimeSlotsIsOpen: false,
            timeSlotsCount: 3,
            timeSlot1: '',
            timeSlot2: '',
            timeSlot3: '',
            timeSlot4: '',
            timeSlot5: '',
        }
    }

    componentDidMount() {
        this.inAppReminderUnsubscriber = this.dbref.doc('notifications').onSnapshot(this.onInAppReminderSnapshot);
        this.businessColorUnsubscriber = this.dbref.doc('theme').onSnapshot(this.onBusinessColorSnapshot);
        this.settingsUnsubscriber = this.dbref.doc('notifications').collection('daysBefore').orderBy('daysBefore').onSnapshot(this.onSettingsSnapshot);
        this.teamsUnsubscriber = this.dbref.doc('teams').collection('teams').orderBy('name').onSnapshot(this.onTeamsSnapshot);
        this.timeSlotsUnsubscriber = this.dbref.doc('timeSlots').onSnapshot(this.onTimeSlotsSnapshot);
    }

    componentWillUnmount() {
        if (this.settingsUnsubscriber) this.settingsUnsubscriber();
        if (this.businessColorUnsubscriber) this.businessColorUnsubscriber();
        if (this.inAppReminderUnsubscriber) this.inAppReminderUnsubscriber();
        if (this.teamsUnsubscriber) this.teamsUnsubscriber();
        if (this.timeSlotsUnsubscriber) this.timeSlotsUnsubscriber();
    }

    onBusinessColorSnapshot = async querySnapshot => {
        const doc = await this.dbref.doc('theme').get();
        if (!doc.exists) return;

        const { brandPrimary } = doc.data();

        this.setState({ brandPrimary });
    }

    onSettingsSnapshot = async querySnapshot => {
        let settings = [];
        querySnapshot.forEach(doc => {
            const data = doc.data();
            settings.push({
                key: doc.id,
                ...data
            });
        });
        this.setState({ settings });
    }
    fillDefaultDataDatabase = async () => {
        //roles
        const rolesRef = db.collection('roles');
        const userRoleRef = rolesRef.doc(RoleUtils.Roles.User);
        const wijkcoordinatorRoleRef = rolesRef.doc(RoleUtils.Roles.Wijkcoordinator);
        const userRole = await userRoleRef.get();
        const wijkcoordinatorRole = await wijkcoordinatorRoleRef.get();
        if (!userRole.exists) {
            userRoleRef.set({
                alias: "Teamlid",
                key: RoleUtils.Roles.User
            })
        }
        if (!wijkcoordinatorRole.exists) {
            wijkcoordinatorRoleRef.set({
                alias: "Wijkcoördinator",
                key: RoleUtils.Roles.Wijkcoordinator
            })
        }
        const inAppRef = db.neighbourhoodCollection("settings").doc("notifications");
        const inApp = await inAppRef.get();
        if (!inApp.exists) {
            inAppRef.set({
                inAppReminderDaysBefore: 6,
            })
        }
        var daysExists = false;
        const daysBeforeRef = db.neighbourhoodCollection("settings").doc("notifications").collection("daysBefore");
        await daysBeforeRef.get().then(function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
                if (doc.data.length > 0)
                    daysExists = true;
            });
        });
        if (!daysExists) {
            daysBeforeRef.doc("14").set({
                daysBefore: 14,
            })
            daysBeforeRef.doc("7").set({
                daysBefore: 7,
            })
        }

    }
    onTeamsSnapshot = async querySnapshot => {
        let teams = [];
        querySnapshot.forEach(doc => {
            const data = doc.data();
            teams.push({
                key: doc.id,
                ...data
            });
        });
        this.setState({ teams });
    }

    onInAppReminderSnapshot = async querySnapshot => {
        const doc = await this.dbref.doc('notifications').get();
        if (!doc.exists) return;

        const { inAppReminderDaysBefore } = doc.data();

        this.setState({ inAppReminderDaysBefore });
    }

    onTimeSlotsSnapshot = async querySnapshot => {
        if (!querySnapshot.exists) {
            this.setState({
                timeSlotsCount: 3,
                timeSlot1: 'Middag',
                timeSlot2: 'Vroege avond',
                timeSlot3: 'Late avond',
                timeSlot4: '',
                timeSlot5: '',
            });
            return;
        }
        this.setState(querySnapshot.data());
    }

    onInputChange = event => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({ [name]: value });
    }
    saveBrandPrimary = async event => {

        const { brandPrimary } = this.state;
        await this.dbref.doc('theme').update({
            brandPrimary
        });
        showSnackbar('Themakleur is opgeslagen');
    }

    saveInAppReminderDaysBeforeHelp = async event => {


        const { inAppReminderDaysBefore } = this.state;

        if (inAppReminderDaysBefore <= 0) {
            showSnackbarWithType('Het aantal dagen mag niet nul zijn', 'error');
            return;
        }
        await this.dbref.doc('notifications').set({
            inAppReminderDaysBefore: parseInt(inAppReminderDaysBefore, 10)
        });
        showSnackbar('Nieuwe notificatie is opgeslagen');

    }

    saveNewDaysBefore = async event => {


        const { newDaysBefore } = this.state;
        const newDaysBeforeKey = newDaysBefore.toString();

        if (newDaysBefore <= 0) {
            showSnackbarWithType('De waarde van het aantal dagen voor het einde van een maand moet hoger zijn dan 0!')
            return;
        }

        const doc = await this.dbref
            .doc('notifications')
            .collection('daysBefore')
            .doc(newDaysBeforeKey)
            .get();
        showSnackbar('Nieuwe notificatie is opgeslagen');
        if (doc.exists) {
            showSnackbarWithType('Notificatie met de waarde ' + newDaysBefore + ' bestaat reeds al!');
            return;
        }
        await this.dbref
            .doc('notifications')
            .collection('daysBefore')
            .doc(newDaysBeforeKey).set({
                daysBefore: parseInt(newDaysBefore, 10)
            });
    }

    removeDaysBefore = async daysBefore => {
        await this.dbref
            .doc('notifications')
            .collection('daysBefore')
            .doc(daysBefore.key).delete();

        showSnackbar('Notificatie is verwijderd');
    }

    addTeam = async () => {
        const { newTeam, teamColor } = this.state;

        if (newTeam.length === 0) {
            showSnackbarWithType('Voer een teamnaam in')
            return;
        }

        const doc = await this.dbref
            .doc('teams')
            .collection('teams')
            .doc(newTeam)
            .get();

        if (doc.exists) {
            showSnackbarWithType('Team met de naam: ' + newTeam + ' bestaat reeds al!');
            return;
        }

        showSnackbar('Team is toegevoegd');

        await this.dbref
            .doc('teams')
            .collection('teams')
            .doc(newTeam).set({
                name: newTeam,
                color: teamColor
            });

    }


    removeTeam = async teams => {
        await this.dbref
            .doc('teams')
            .collection('teams')
            .doc(teams.key).delete();

        showSnackbar('Team is verwijderd');

    }

    onChangeBrandPrimary = element => {
        const brandPrimary = element.hex;

        this.setState({ brandPrimary });
    }

    onChangeTeamColor = element => {
        const teamColor = element.hex;

        this.setState({ teamColor });
    }

    openPrimaryColorModal = (e) => {
        e.preventDefault();
        this.setState({ modalPrimaryColorIsOpen: true });
    }

    closePrimaryColorModal = () => {
        this.setState({ modalPrimaryColorIsOpen: false });
    }
    openDefaultDatabaseModal = () => {
        this.setState({ modalDefaultDatabaseIsOpen: true });
    }
    closeDefaultDatabaseModal = () => {
        this.setState({ modalDefaultDatabaseIsOpen: false });
    }
    handlePrimaryColorClicked = () => {
        this.closePrimaryColorModal();
        this.saveBrandPrimary();
    }

    openInAppReminderModal = (e) => {
        e.preventDefault();
        this.setState({ modalInAppReminderIsOpen: true });
    }

    closeInAppReminderModal = () => {
        this.setState({ modalInAppReminderIsOpen: false });
    }

    handleInAppReminderClicked = () => {
        this.closeInAppReminderModal();
        this.saveInAppReminderDaysBeforeHelp();
    }

    openAmountOfDaysModal = (e) => {
        e.preventDefault();
        this.setState({ modalAmountOfDaysIsOpen: true });
    }

    closeAmountOfDaysModal = () => {
        this.setState({ modalAmountOfDaysIsOpen: false });
    }

    handleAmountOfDaysClicked = () => {
        this.closeAmountOfDaysModal();
        this.saveNewDaysBefore();
    }

    openAmountOfDaysDeleteModal = daysBefore => {
        this.setState({ selectedDaysBefore: daysBefore, modalAmountOfDaysDeleteIsOpen: true });
    }

    closeAmountOfDaysDeleteModal = () => {
        this.setState({ selectedDaysBefore: null, modalAmountOfDaysDeleteIsOpen: false });
    }
    handleAmountOfDaysDeleteClicked = () => {
        this.removeDaysBefore(this.state.selectedDaysBefore);
        this.closeAmountOfDaysDeleteModal();
    }

    closeAddTeamModal = () => {
        this.setState({ modalAddTeamIsOpen: false });
    }
    openAddTeamModal = (e) => {
        e.preventDefault();
        this.setState({ modalAddTeamIsOpen: true });
    }

    handleAddTeamClicked = () => {
        this.addTeam();
        this.closeAddTeamModal();
    }

    openTeamDeleteModal = team => {
        this.setState({ selectedTeam: team, modalTeamDeleteIsOpen: true });
    }

    closeTeamDeleteModal = () => {
        this.setState({ selectedTeam: null, modalTeamDeleteIsOpen: false });
    }

    handleTeamDeleteClicked = () => {
        this.removeTeam(this.state.selectedTeam);
        this.closeTeamDeleteModal();
    }

    openTimeSlotsModal = e => {
        e.preventDefault();
        this.setState({ modalTimeSlotsIsOpen: true });
    }
    closeTimeSlotsModal = _ => {
        this.setState({ modalTimeSlotsIsOpen: false });
    }
    handleSaveTimeSlots = async _ => {
        const {
            timeSlotsCount,
            timeSlot1,
            timeSlot2,
            timeSlot3,
            timeSlot4,
            timeSlot5,
        } = this.state;
        await this.dbref.doc('timeSlots').set({
            timeSlotsCount,
            timeSlot1,
            timeSlot2,
            timeSlot3,
            timeSlot4,
            timeSlot5,
        });
        showSnackbar('Dagdelen zijn opgeslagen');
        this.closeTimeSlotsModal();
    }

    renderSetting = setting => {
        const dayText = setting.daysBefore === 1 ? "dag" : "dagen";
        return (
            <tr key={setting.key}>
                <td>{setting.daysBefore} {dayText} voor het einde van de maand</td>
                <td>
                    <button className="btn btn-danger" title="Verwijder" data-toggle="tooltip"
                        onClick={() => this.openAmountOfDaysDeleteModal(setting)}>
                        <i className="material-icons">delete_forever</i>
                    </button>
                </td>
            </tr>
        )
    }

    teamSetting = team => {
        return (
            <tr key={team.key}>
                <td>{team.name}</td>
                <td style={{ backgroundColor: team.color }}></td>
                <td>
                    <button className="btn btn-danger" title="Verwijder" data-toggle="tooltip"
                        onClick={() => this.openTeamDeleteModal(team)}>
                        <i className="material-icons">delete_forever</i>
                    </button>
                </td>
            </tr>
        )
    }

    render() {
        return (
            <div>
                <div className="container  content">

                    <div className="card">
                        <TimeSlotsCollapse />
                        <div id="settingsTimeSlotsCollapse" className="card-body collapse in">
                            <TimeSlotsSetting
                                openTimeSlotsModal={this.openTimeSlotsModal}
                                timeSlotsCount={this.state.timeSlotsCount}
                                timeSlot1={this.state.timeSlot1}
                                timeSlot2={this.state.timeSlot2}
                                timeSlot3={this.state.timeSlot3}
                                timeSlot4={this.state.timeSlot4}
                                timeSlot5={this.state.timeSlot5}
                                onChange={this.onInputChange}
                            />
                        </div>
                    </div>

                    <hr />

                    <div className="card">
                        <NotificationCollapse />
                        <div id="settingsNotificationCollapse" className="card-body collapse in">
                            <InAppReminderDaysBefore
                                openInAppReminderModal={this.openInAppReminderModal}
                                inAppReminderDaysBefore={this.state.inAppReminderDaysBefore}
                                onChange={this.onInputChange} />
                            <hr />

                            <PushNotificationReminderDaysBefore
                                openAmountOfDaysModal={this.openAmountOfDaysModal}
                                newDaysBefore={this.state.newDaysBefore}
                                onInputChange={this.onInputChange} />

                            <PushNotificationsTable
                                //  openAmountOfDaysDeleteModal={this.openAmountOfDaysDeleteModal}
                                settings={this.state.settings}
                                renderSetting={this.renderSetting} />
                        </div>
                    </div>

                    <hr />

                    <div className="card">
                        <ToggleTeamsCard />
                        <div id="settingsTeamsCollapse" className="card-body collapse in">
                            <TeamsAddTeam
                                saveNewTeam={this.openAddTeamModal}
                                teamColor={this.state.teamColor}
                                onChangeTeamColor={this.onChangeTeamColor}
                                onInputChange={this.onInputChange}
                            />

                            <TeamsTable
                                teams={this.state.teams}
                                teamSetting={this.teamSetting} />
                        </div>
                    </div>

                    {this.props.rootUserData && RoleUtils.hasRole(this.props.rootUserData, RoleUtils.Roles.Administrator) && (
                        <div className="card" style={{ marginTop: 15 }}>
                            <ToggleInstallationCard />
                            <div id="settingsInstallationCollapse" className="card-body collapse">
                                <FillDefaultDataBase
                                    openDefaultDatabaseModal={this.openDefaultDatabaseModal}
                                />

                                <div>
                                    <button
                                        className="btn btn-outline-warning"
                                        type="button"
                                        onClick={async () => {
                                            const migrateRouteTrackingToTracking = firebase.functions().httpsCallable('migrateRouteTrackingToTracking');
                                            const neighbourhoodId = getNeighbourhoodId();
                                            const result = await migrateRouteTrackingToTracking({ neighbourhoodId, doDelete: false });
                                            console.log('migrateRouteTrackingToTracking result', result);
                                        }}
                                    >
                                        <span>Migrate routeTracking to tracking</span>
                                    </button>

                                    <button
                                        className="btn btn-outline-warning"
                                        type="button"
                                        onClick={async () => {
                                            const removeDisplayNames = firebase.functions().httpsCallable('removeDisplayNames');
                                            const dateFrom = prompt('dateFrom');
                                            const dateTo = prompt('dateTo');
                                            const dryRun = prompt('dryRun? "no" for not') !== 'no';
                                            const result = await removeDisplayNames({ dateFrom, dateTo, dryRun });
                                            console.log('removeDisplayNames result', result);
                                        }}
                                    >
                                        <span>Remove display names</span>
                                    </button>

                                    <button
                                        className="btn btn-outline-warning"
                                        type="button"
                                        onClick={async () => {
                                            const setLastDateOfMonth = firebase.functions().httpsCallable('setLastDateOfMonth');
                                            const dateFrom = prompt('dateFrom');
                                            const dateTo = prompt('dateTo');
                                            const dryRun = prompt('dryRun? "no" for not') !== 'no';
                                            const result = await setLastDateOfMonth({ dateFrom, dateTo, dryRun });
                                            console.log('setLastDateOfMonth result', result);
                                        }}
                                    >
                                        <span>Set last date of month</span>
                                    </button>

                                    <button
                                        className="btn btn-outline-warning"
                                        type="button"
                                        onClick={async () => {
                                            const resetNeighbourhoodSubdocumentCounter = firebase.functions().httpsCallable('resetNeighbourhoodSubdocumentCounter');
                                            const neighbourhoodId = prompt('neighbourhoodId');
                                            const collectionId = prompt('collectionId');
                                            const ignoreIgnoredCollections = prompt('ignoreIgnoredCollections? "yes" for true') === 'yes';
                                            const result = await resetNeighbourhoodSubdocumentCounter({ neighbourhoodId, collectionId, ignoreIgnoredCollections });
                                            console.log('resetNeighbourhoodSubdocumentCounter result', result);
                                        }}
                                    >
                                        <span>Reset Neighbourhood Subdocument Counter</span>
                                    </button>

                                    <button
                                        className="btn btn-outline-warning"
                                        type="button"
                                        onClick={async () => {
                                            const resetNeighbourhoodSubdocumentCounters = firebase.functions().httpsCallable('resetNeighbourhoodSubdocumentCounters');
                                            const ignoreIgnoredCollections = prompt('ignoreIgnoredCollections? "yes" for true') === 'yes';
                                            const result = await resetNeighbourhoodSubdocumentCounters({ ignoreIgnoredCollections });
                                            console.log('resetNeighbourhoodSubdocumentCounters result', result);
                                        }}
                                    >
                                        <span>Reset Neighbourhood Subdocument Counters</span>
                                    </button>

                                    <button
                                        className="btn btn-outline-warning"
                                        type="button"
                                        onClick={async () => {
                                            const getUserToken = firebase.functions().httpsCallable('getUserToken');
                                            const uid = prompt('UID of the user to impersonate');
                                            const result = await getUserToken({ uid });
                                            console.log('getUserToken result', result);
                                            firebase.auth().signInWithCustomToken(result.data);
                                        }}
                                    >
                                        <span>impersonate</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <ConfirmationModal
                    title={'Aantal dagdelen'}
                    message={'Weet je zeker dat je de dagdelen wilt aanpassen?'}
                    isOpen={this.state.modalTimeSlotsIsOpen}
                    close={this.closeTimeSlotsModal}
                    confirm={this.handleSaveTimeSlots}
                />
                <ConfirmationModal
                    title={'Themakleur aanpassen'}
                    message={'Weet je zeker dat je deze themakleur wilt aanpassen?'}
                    isOpen={this.state.modalPrimaryColorIsOpen}
                    close={this.closePrimaryColorModal}
                    confirm={this.handlePrimaryColorClicked}
                />
                <ConfirmationModal
                    title={'In-app herinnering beschikbaarheid'}
                    message={'Weet je zeker dat je het aantal dagen voor de notificatie wilt bevestigen?'}
                    isOpen={this.state.modalInAppReminderIsOpen}
                    close={this.closeInAppReminderModal}
                    confirm={this.handleInAppReminderClicked}
                />
                <ConfirmationModal
                    title={'Notificatie instelling'}
                    message={'Weet je zeker dat je het aantal dagen voor de notificatie wilt bevestigen?'}
                    isOpen={this.state.modalAmountOfDaysIsOpen}
                    close={this.closeAmountOfDaysModal}
                    confirm={this.handleAmountOfDaysClicked}
                />
                <ConfirmationModal
                    title={'Notificatie verwijderen'}
                    message={'Weet je zeker dat je deze notificatie wilt verwijderen?'}
                    isOpen={this.state.modalAmountOfDaysDeleteIsOpen}
                    close={this.closeAmountOfDaysDeleteModal}
                    confirm={this.handleAmountOfDaysDeleteClicked}
                    confirmButtonClassName={'btn-danger'}
                    confirmButtonText={'Verwijderen'}
                />
                <ConfirmationModal
                    title={'Team toevoegen'}
                    message={'Weet je zeker dat je het team wilt toevoegen?'}
                    isOpen={this.state.modalAddTeamIsOpen}
                    close={this.closeAddTeamModal}
                    confirm={this.handleAddTeamClicked}
                    confirmButtonText={'Toevoegen'}
                />
                <ConfirmationModal
                    title={'Team verwijderen'}
                    message={'Weet je zeker dat je dit team wilt verwijderen?'}
                    isOpen={this.state.modalTeamDeleteIsOpen}
                    close={this.closeTeamDeleteModal}
                    confirm={this.handleTeamDeleteClicked}
                    confirmButtonClassName={'btn-danger'}
                    confirmButtonText={'Verwijderen'}
                />
                <ConfirmationModal
                    title={'Standaarwaarden'}
                    message={'Weet je zeker dat je de standaardwaarden in de databse wilt zetten?'}
                    isOpen={this.state.modalDefaultDatabaseIsOpen}
                    close={this.closeDefaultDatabaseModal}
                    confirm={this.fillDefaultDataDatabase}
                    confirmButtonClassName={'btn-danger'}
                    confirmButtonText={'Ja'}
                />
            </div >
        )
    }
}

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(withRouter(EditSettings));

export {
    EditSettings
};
