import React from 'react';
import Modal from 'react-modal';
import AvailabilityUserOverviewTable from './AvailabilityUserOverviewTable';

export default function AvailabilityUserOverviewModal(props) {
	return (
		<Modal
			className="Modal__Bootstrap modal-dialog modal-lg modal-scrollable"
			closeTimeoutMS={150}
			isOpen={props.isModalOpen}
			onRequestClose={props.toggleModal}
			ariaHideApp={false}
		>
			<div className="modal-content">
				<div className="modal-header">
					<h4 className="modal-title">Beschikbaarheid ingevoerd</h4>
					<button type="button" className="close" onClick={props.toggleModal}>
						<span aria-hidden="true">&times;</span>
						<span className="sr-only">Sluiten</span>
					</button>
				</div>
				<div className="modal-body">
					<AvailabilityUserOverviewTable
						users={props.users}
						preferences={props.preferences}
					/>
				</div>
				<div className="modal-footer">
					<button type="button" className="btn btn-secondary" onClick={props.toggleModal}>Sluiten</button>
				</div>
			</div>
		</Modal>
	)
};