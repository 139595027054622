import React, { Component } from 'react';
import { withUsers } from '../../context/UsersContext';
import AvailabilityUserNonPlanning from './AvailabilityUserNonPlanning';
import AvailabilityUserPlanning from './AvailabilityUserPlanning';

class AvailabilityUser extends Component {
    componentDidMount() {
        this.props.usersContextActions.register(this);
    }
    componentWillUnmount() {
        this.props.usersContextActions.unregister(this);
    }

    render() {
        const displayName = this.props.usersContextActions.uidToDisplayName(this.props.user.uid);
        return (
            <span>
                {this.props.isPlanning
                    ? <AvailabilityUserPlanning
                        user={this.props.user}
                        isPlanning={this.props.isPlanning}
                        displayName={displayName}
                        planEntry={this.props.planEntry}
                        planEntries={this.props.planEntries}
                        preference={this.props.preference}
                        preferences={this.props.preferences}
                        onUserGroupChange={this.props.onUserGroupChange}
                        onUserPlanClick={this.props.onUserPlanClick}
                        amountEntriesUser={this.props.amountEntriesUser}
                        teams={this.props.teams}
                    />

                    : <AvailabilityUserNonPlanning
                        displayName={displayName}
                        planEntry={this.props.planEntry}
                        teams={this.props.teams}
                    />}
            </span>
        )
    }
}

export default withUsers(AvailabilityUser);